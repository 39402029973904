import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Pipe
} from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';

type InputType = 'text' | 'number';

const DEFAULT_ERROR_MESSAGE = 'Invalid value';
const DEFAULT_VALIDATOR_MESSAGES: { [key: string]: string } = {
  min: 'Value is too small',
  max: 'Value is too big',
  required: 'Value is required',
  email: 'Email is incorrect',
  minlength: 'Value is too short',
  maxlength: 'Value is too long',
  pattern: 'Value does not match provided pattern'
};

const mapErrorMessages = (errors: ValidationErrors): string[] => {
  return Object.keys(errors).map(key => {
    const { message } = errors[key];

    if (errors[key].message) {
      return message;
    }

    return DEFAULT_VALIDATOR_MESSAGES[key];
  });
};

const getErrorMessage = ({ errors }: FormControl): string => {
  if (!errors) {
    return DEFAULT_ERROR_MESSAGE;
  }

  const [message] = mapErrorMessages(errors);

  return message || DEFAULT_ERROR_MESSAGE;
};

@Component({
  selector: 'vl-input-group',
  styleUrls: ['input-group.component.scss'],
  templateUrl: 'input-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VlInputGroupComponent implements OnInit {
  @Input() labelText: string;
  @Input() inputControl: FormControl;
  @Input() inputType: InputType = 'text';
  @Input() isReadonly: boolean;
  @Input() isValidationHidden?: boolean;
  @Input() valuePipe?: Pipe;
  @Input() valuePipeArguments?: unknown[];

  @Output() private inputBlur = new EventEmitter<void>();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.inputControl.registerOnChange(() => this.cdr.markForCheck());
  }

  onInputBlur(): void {
    this.inputBlur.emit();
  }

  get isInputInvalid(): boolean {
    const { touched, invalid } = this.inputControl;

    return touched && invalid;
  }

  get errorMessage(): string {
    return getErrorMessage(this.inputControl);
  }
}
