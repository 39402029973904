import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Directive, EventEmitter, OnDestroy, Output } from '@angular/core';
import { ExcelService, ParsedExcelRow, ParserOptions, RequestedCellValue } from '../../services/excel.service';

@Directive({
    selector: '[vlSpreadsheetParser]',
    exportAs: 'spreadsheetParserApi',
    providers: [ExcelService]
})
export class SpreadsheetParseDirective implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    @Output() private parseComplete = new EventEmitter<[ParsedExcelRow[], RequestedCellValue]>();
    @Output() private parseFail = new EventEmitter<string>();

    constructor(private service: ExcelService) {}

    parseSpreadsheet(files: FileList, options: ParserOptions = {}): void {
        this.service
            .parse(files[0], options)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                (data: [ParsedExcelRow[], RequestedCellValue]) => {
                    this.parseComplete.emit(data);
                },
                (errorMessage) => {
                    this.parseFail.emit(errorMessage)
                }
            );
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
    }
}
