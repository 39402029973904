import { ButtonRole } from './button';
import { AbstractControl, AsyncValidatorFn, FormGroup, ValidatorFn } from '@angular/forms';

export enum DocumentTemplateSettingType {
  TEXT = 'TEXT',
  SELECT = 'SELECT',
  CHECKBOX = 'CHECKBOX',
  RADIO = 'RADIO',
  DATE = 'DATE'
}

export class TemplateQuery {
  id?: string;
  queryName: string;
  resultObjectName: string;
  objectName: string;
  fields: string[] = [];
  statement: string;
}

export interface TemplateProperty {
  id: string;
  name: string;
  label: string;
  value: string;
  type?: DocumentTemplateSettingType;
  isTechnical?: boolean;
  lov?: string[];
}

export interface DocumentTemplate {
  id: string;
  fileId: string;
  fileName: string;
  fileExtension?: string;
  name: string;
  script: string;
  queries: TemplateQuery[];
  properties?: TemplateProperty[];
}

export interface TemplateAction {
  buttonRole: ButtonRole;
  buttonLabel: string;
  buttonActionName: string;
  isButtonHidden?: boolean;
  isDisabled?: boolean;
  tooltip?: string;
}

export interface TemplatePropertyMap {
  [key: string]: TemplatePropertyMapItem;
}

export interface TemplatePropertyMapItem {
  controlLabel: string;
  controlType: DocumentTemplateSettingType;
  controlLov?: string[];
  isVisibleInTableView?: boolean;
  mapToArray?: boolean;
  tableOrder?: number;
  validators?: ValidatorFn[];
  asyncValidators?: AsyncValidatorFn[];
  updateOn?: 'change' | 'blur' | 'submit';
  onInitMappedControls?: (mappedControl: TemplatePropertyMapItem & { control: AbstractControl }, form: FormGroup, ) => void;
}

export interface DocumentAttachment extends File {
  id: string;
  fileId?: string;
  fileName: string;
  fileExtension?: string;
  templateId: string;
  order: number;
  isChecked: boolean;
}

export interface TemplateSaveEmitPaylaod {
  template: DocumentTemplate;
  file?: File;
  resolve?: () => void;
}
