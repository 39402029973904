export enum ButtonRole {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Outline = 'Outline',
  Plain = 'Plain',
  Tag = 'Tag',
  Dropdown = 'Dropdown',
  Alert = 'Alert',
  Discreet = 'Discreet'
}
