import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { ButtonRole, Filter, MenuFilter } from '../../../models';
import { FilterListComponent } from '../filter-list/filter-list.component';
import { ConditionFilterService } from '../service/condition.filter.service';

interface OnFilterUpdateEmmitable {
  filters: Filter[];
  searchService: ConditionFilterService;
}

@Component({
  selector: 'vle-default-filter',
  styleUrls: ['default-filter.component.scss'],
  templateUrl: 'default-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultFilterComponent {
  @Input() leftMenuFilter: MenuFilter;
  @Input() rightMenuFilters: MenuFilter[] = [];
  @Input() textFilter: Filter;
  @Input() filters: Filter[] = [];

  ButtonRole = ButtonRole;

  @ViewChild(FilterListComponent, { static: true }) filterList: FilterListComponent;

  @Output() private filterUpdate = new EventEmitter<OnFilterUpdateEmmitable>();

  constructor(private cdr: ChangeDetectorRef) {}

  onFilterUpdate(filters: Filter[]): void {
    this.filterUpdate.emit({ filters, searchService: this.filterList.searchService });
    this.cdr.detectChanges();
  }

  onFilterUpsert(filter: Filter, index?: number): void {
    this.filterList.upsertFilter(filter, index);
  }

  onFilterRemove(index: number): void {
    this.filterList.removeFilter(index);
  }

  trackByFilterName = (_: number, { filterable }: Filter): string => filterable?.name;
}
