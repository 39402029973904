import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { CustomMenuItem, MenuItem, MenuItemEvent, MenuItemEventType, RowMenuConfig, RowType } from '../../../../models';

interface MenuStyles {
  top: string;
}

@Component({
  selector: 'vd-menu-renderer',
  templateUrl: './menu-renderer.component.html',
  styleUrls: ['./menu-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DemoMenuRendererComponent implements ICellRendererAngularComp {
  cellParams: ICellRendererParams;
  itemEvent = MenuItemEventType;
  isMenuActive: boolean;
  isSubmenuActive: boolean;

  menuConfig: RowMenuConfig;
  menuStyles: MenuStyles;

  @ViewChild('moreMenu', { static: false }) private moreMenu: ElementRef;

  agInit(params: ICellRendererParams): void {
    this.cellParams = params;
    this.menuConfig = params.value;
  }

  refresh(params: ICellRendererParams): boolean {
    this.cellParams = params;
    this.menuConfig = params.value;

    return true;
  }

  getAddMenuItems(rowTypes: RowType[] = []): MenuItem[] {
    return [
      { id: '0', value: 'Product', rowType: RowType.PRODUCT },
      { id: '2', value: 'Comment', rowType: RowType.COMMENT },
      { id: '1', value: 'Sub-total', rowType: RowType.SUBTOTAL }
    ].filter(i => rowTypes.includes(i.rowType));
  }

  onShowMenu(): void {
    if (!this.moreMenu) {
      return;
    }

    this.isMenuActive = true;

    const { top } = this.moreMenu.nativeElement.getBoundingClientRect();
    this.menuStyles = {
      top: top + 'px'
    };
  }

  onHideMenu(): void {
    this.isMenuActive = false;
  }

  onSubmenuToggle(value: boolean): void {
    this.isSubmenuActive = value;
  }

  onSubmenuItemSelect(item: MenuItem): void {
    this.selectMenuItem({ type: MenuItemEventType.ADD, data: item.rowType });
  }

  onItemClick(eventType: MenuItemEventType): void {
    this.selectMenuItem({ type: eventType });
  }

  onCustomItemClick(customItemEventType: any): void {
    this.selectMenuItem({ type: MenuItemEventType.CUSTOM, data: customItemEventType });
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  trackByEventType = ({ eventType }: CustomMenuItem): any => eventType;

  private selectMenuItem(menuItemEvent: MenuItemEvent): void {
    const { context, node } = this.cellParams;

    context.componentParent.menuItemSelected(menuItemEvent, node);
  }
}
