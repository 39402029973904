import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonRole } from '../../models';

const buttonClassName = 'vle-button';

const buttonRoleClassMap = {
  [ButtonRole.Primary]: `${buttonClassName}--primary`,
  [ButtonRole.Secondary]: `${buttonClassName}--secondary`,
  [ButtonRole.Outline]: `${buttonClassName}--outline`,
  [ButtonRole.Plain]: `${buttonClassName}--plain`,
  [ButtonRole.Tag]: `${buttonClassName}--tag`,
  [ButtonRole.Dropdown]: `${buttonClassName}--dropdown`,
  [ButtonRole.Alert]: `${buttonClassName}--alert`,
  [ButtonRole.Discreet]: `${buttonClassName}--discreet`
};

@Component({
  selector: 'vle-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  @Input() buttonRole?: ButtonRole = ButtonRole.Primary;
  @Input() isButtonDisabled?: boolean;
  @Input() hasNoOffset?: boolean;
  @Input() isWide?: boolean;

  @Output() private readonly buttonClick = new EventEmitter<MouseEvent>();

  onButtonClick(event: MouseEvent): void {
    this.buttonClick.emit(event);
  }

  get buttonClassMap(): { [key: string]: boolean } {
    return {
      [buttonRoleClassMap[this.buttonRole]]: true,
      [`${buttonClassName}--has-no-offset`]: this.hasNoOffset,
      [`${buttonClassName}--wide`]: this.isWide
    };
  }
}
