import { Observable, of } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

interface ConfigurationSettings {
  id: string;
  key: string;
  value: string;
}

@Injectable()
export class ReleaseVersionService {
  NOT_AVAILABLE = 'n.a.';
  integrationVersion$: Observable<string>;
  backendVersion$: Observable<string>;
  UIVersion$: Observable<string>;

  constructor(private http: HttpClient) {}

  getBackendVersion(path: string): Observable<string> {
    if (!this.backendVersion$) {
      this.backendVersion$ = this.http.get<{ git_branch: string }>(window.VELO_API + path).pipe(
        map(({ git_branch }) => git_branch),
        catchError(() => {
          return of(this.NOT_AVAILABLE);
        }),
        publishReplay(1),
        refCount()
      );
    }
    return this.backendVersion$;
  }

  getUIVersion(path: string): Observable<string> {
    if (!this.UIVersion$) {
      this.UIVersion$ = this.http.get<{ git_branch: string }>(window.VELO_PUBLIC_PATH + path).pipe(
        map(({ git_branch }) => git_branch),
        catchError(() => {
          return of(this.NOT_AVAILABLE);
        }),
        publishReplay(1),
        refCount()
      );
    }
    return this.UIVersion$;
  }

  getIntegrationVersion(param: string): Observable<string> {
    if (!this.integrationVersion$) {
      this.integrationVersion$ = this.fetchSettings(param).pipe(
        map(({ value }: { value: string }) => JSON.parse(value)?.version),
        catchError(() => {
          return of(this.NOT_AVAILABLE);
        }),
        publishReplay(1),
        refCount()
      );
    }
    return this.integrationVersion$;
  }

  private fetchSettings(settingsKey: string): Observable<ConfigurationSettings> {
    return this.http.get<ConfigurationSettings>(`${window.VELO_API}/services/configuration-settings/${settingsKey}`, {
      headers: {
        Authorization: window.VELO_KEY
      }
    });
  }
}
