import { BehaviorSubject, from, merge, Observable, of, Subject } from 'rxjs';
import { debounceTime, filter, finalize, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DropdownComponent } from '../../components/dropdown/dropdown.component';
import { InputGroupComponent } from '../../components/input-group/input-group.component';
import { AsteriskAlign, DropdownItem, ItemLikeConfig, TypeaheadItem } from '../../models';
import { HighlightIntersectionPipe } from '../../pipes';

@Component({
  selector: 'vle-typeahead',
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TypeaheadComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() inputControl = new FormControl();
  @Input() itemsGetter: (value: string) => Promise<TypeaheadItem[]>;
  @Input() debounceTime: number;
  @Input() minChars = 1;
  @Input() isDropup = false;
  @Input() isDisabled = false;
  @Input() isOpenOnInit = false;
  @Input() iconClass: string;
  @Input() menuClass: string;
  @Input() filterResultsByInput = true;
  @Input() appendToBody = true;
  @Input() allowInsideClick = false;
  @Input() labelText?: string;
  @Input() asteriskAlign?: AsteriskAlign;
  @Input() isRightAligned?: boolean;
  @Input() isDropdownView?: boolean;

  filteredItemsLength: number;

  defaultItem: DropdownItem = {
    value: null,
    displayValue: 'No result',
    className: 'vle-typeahead-default-item',
    isUnavailable: true
  };

  itemLikeConfig: ItemLikeConfig = {
    valuePipe: HighlightIntersectionPipe,
    valuePipeArguments: ''
  };

  isOpen: boolean;
  autofocus = false;

  filteredItems$: Observable<TypeaheadItem[]>;
  isLoading$ = new BehaviorSubject(false);
  destroy$ = new Subject<void>();

  @ViewChild('input', { static: false }) private input: InputGroupComponent;
  @ViewChild('dropdown', { static: true }) private dropdown: DropdownComponent;

  @Output() private itemSelect = new EventEmitter<TypeaheadItem>();
  @Output() private inputBlur = new EventEmitter<string>();

  ngOnInit(): void {
    this.setValuePipeArguments();
    this.autofocus = this.isOpenOnInit;
  }

  ngOnChanges(changes: SimpleChanges) {
    const { inputControl } = changes;

    if (inputControl && inputControl.currentValue !== inputControl.previousValue) {
      const inputControlCurrent = inputControl.currentValue;
      this.filteredItems$ = merge(
        of(inputControlCurrent.value),
        inputControlCurrent.valueChanges.pipe(debounceTime(this.debounceTime || 200)),
        this.itemSelect.pipe(map(item => item.displayValue))
      ).pipe(
        map(value => (value ? value.trim().toLowerCase() : '')),
        filter(value => !value || value.length >= this.minChars),
        tap(() => this.isLoading$.next(true)),
        switchMap(value => {
          return (!!value || this.isDropdownView ? from(this.itemsGetter(value)) : of([])).pipe(
            map(items => {
              this.setValuePipeArguments();

              const result = this.filterResultsByInput
                ? items.filter(i => i.displayValue.toLowerCase().includes(value))
                : items;
              this.filteredItemsLength = result.length;
              return result.length ? result : [this.defaultItem];
            }),
            finalize(() => this.isLoading$.next(false))
          );
        }),
        takeUntil(this.destroy$)
      );
    }
  }

  ngAfterViewInit(): void {
    if (this.isOpenOnInit) {
      setTimeout(() => {
        this.dropdown.show();
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setValuePipeArguments(): void {
    this.itemLikeConfig = {
      ...this.itemLikeConfig,
      valuePipeArguments: [this.inputControl.value]
    };
  }

  onInputClick(event: MouseEvent): void {
    if (this.inputControl.disabled) {
      event.stopPropagation();
    } else if (this.dropdown.isOpen && event.target === document.activeElement) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  onInputBlur(): void {
    this.inputBlur.emit(this.inputControl?.value);
  }

  onItemSelect(item: TypeaheadItem): void {
    this.inputControl.setValue(item.value, { emitEvent: false });
    this.itemSelect.emit(item);
  }
}
