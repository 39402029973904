import { DragulaService } from 'ng2-dragula';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import {
  ButtonRole,
  DocumentAttachment,
  DocumentTemplate,
  DropdownItem,
  TemplateAction,
  TemplateProperty
} from '../../../models';

enum TemplateListTab {
  General = 'General',
  Attachments = 'Attachments'
}

interface NewAttachment {
  templateId: string;
  file: File;
  order?: number;
}

@Component({
  selector: 'vle-template-list',
  styleUrls: ['template-list.component.scss'],
  templateUrl: 'template-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateListComponent implements OnInit, OnDestroy {
  @Input() templates: DocumentTemplate[] = [];
  @Input() generateDocumentActions: TemplateAction[] = [];
  @Input() form: FormGroup;
  @Input() isModalCloseButtonHidden: boolean;
  @Input() isCancelButtonHidden: boolean;
  @Input() isAdminModeEnabled: boolean;

  readonly dragulaGroup = 'attachments';

  TemplateListTab = TemplateListTab;
  ButtonRole = ButtonRole;

  currentTemplateListTab = TemplateListTab.General;
  templateListTabs = Object.values(TemplateListTab);

  @Output() private viewCancel = new EventEmitter<void>();
  @Output() private templateSelect = new EventEmitter<string>();
  @Output() private templateListAction = new EventEmitter<string>();
  @Output() private attachmentAdd = new EventEmitter<NewAttachment>();
  @Output() private attachmentRemove = new EventEmitter<DocumentAttachment>();

  @ViewChild('mirrorContainerRef') private mirrorContainerRef: ElementRef;

  private destroy$ = new Subject<void>();
  private isDragulaInitialized = false;

  constructor(private dragulaService: DragulaService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.templates?.length) {
      const [{ id }] = this.templates;

      this.onTemplateSelect(id);
    }

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.cdr.detectChanges());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.isDragulaInitialized) {
      this.dragulaService.destroy(this.dragulaGroup);
    }
  }

  onViewCancel(): void {
    this.viewCancel.emit();
  }

  onTemplateSelect(templateId: string): void {
    this.templateSelect.emit(templateId);
  }

  onTemplateListTabSelect(tab: TemplateListTab): void {
    this.currentTemplateListTab = tab;

    if (tab === TemplateListTab.Attachments) {
      this.initDragula();
    }
  }

  onTemplateListAction(actionName: string): void {
    this.templateListAction.emit(actionName);
  }

  onToggleAttachment(item: AbstractControl, { checked }: /*HTMLInputElement*/ any): void {
    (item.value as DocumentAttachment).isChecked = checked;
  }

  onAddAttachment(files: FileList): void {
    if (files.length) {
      const file = files[0];
      const { id: templateId, attachments } = this.form.value.template;

      this.attachmentAdd.emit({ templateId, file, order: attachments?.length || 0 });
    }
  }

  onRemoveAttachment(attachment: DocumentAttachment): void {
    this.attachmentRemove.emit(attachment);
  }

  trackByName = (_: number, { name }: TemplateProperty): string => name;

  trackByFileNameValue = (_: number, { value }: AbstractControl): string => value.fileName;

  trackByButtonActionName = (_: number, { buttonActionName }): string => buttonActionName;

  private initDragula(): void {
    if (!this.isDragulaInitialized) {
      this.isDragulaInitialized = true;

      const dragulaOptions = {
        direction: 'vertical',
        mirrorContainer: this.mirrorContainerRef?.nativeElement
      };

      const group = this.dragulaService.find(this.dragulaGroup);
      if (group) {
        group.options = {
          ...group.options,
          ...dragulaOptions
        };
      } else {
        this.dragulaService.createGroup(this.dragulaGroup, dragulaOptions);
      }

      this.dragulaService
        .dropModel()
        .pipe(takeUntil(this.destroy$))
        .subscribe(({ targetModel: sortedDataItems }) => {
          const attachmentsFormArray = this.form.get(['template', 'attachments']) as FormArray;
          attachmentsFormArray.patchValue(sortedDataItems.map(({ value }) => value));

          this.cdr.markForCheck();
        });
    }
  }

  get availableTemplates(): DropdownItem<string>[] {
    return this.templates.map(({ id: value, name: displayValue }) => ({ value, displayValue }));
  }

  get propertyControls(): AbstractControl[] {
    const propertyFormArray = this.form.get(['template', 'properties']) as FormArray;

    return propertyFormArray?.controls || [];
  }

  get attachmentsControls(): AbstractControl[] {
    const attachmentsFormArray = this.form.get(['template', 'attachments']) as FormArray;

    return attachmentsFormArray?.controls || [];
  }

  get isHasAttachments(): boolean {
    return this.attachmentsControls.length > 0;
  }
}
