import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vle-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioComponent {
  @Input() value: string;
  @Input() name?: string;
  @Input() labelText?: string;
  @Input() isSelected?: boolean;
  @Input() isDisabled?: boolean;

  @Output() private select = new EventEmitter<string>();

  onSelect(value: string): void {
    this.select.emit(value);
  }
}
