import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Language, Theme } from '../../models';

@Component({
  selector: 'vle-code-editor',
  templateUrl: './code-editor.component.html',
  styleUrls: ['./code-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CodeEditorComponent implements OnInit, OnDestroy {
  @Input() theme: Theme;
  @Input() language: Language;
  @Input() isDecodingNeeded: boolean;
  @Input() isFormatOnStart: boolean;
  @Input() content: string;

  editorOptions: any;

  private _decodedContent = '';

  get decodedContent(): string {
    return this._decodedContent;
  }

  set decodedContent(value: string) {
    this.valueChanged.emit(value);
    this._decodedContent = value;
  }

  @Output() private save = new EventEmitter<string>();
  @Output() private valueChanged = new EventEmitter<string>();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setOptions();
    this.decodedContent = this.isDecodingNeeded ? atob(this.content || '') : this.content;
  }

  onEditorInit(editor: any): void {
    if (this.isFormatOnStart) {
      editor.getAction('editor.action.formatDocument').run();
    }
  }

  updateState(): void {
    this.setOptions();
    this.cdr.markForCheck();
  }

  updateValue(): void {
    this.decodedContent = this.isDecodingNeeded ? atob(this.content || '') : this.content;
    this.updateState();
  }

  getValue(): string {
    return this.isDecodingNeeded ? btoa(this.decodedContent || '') : this.decodedContent;
  }

  ngOnDestroy(): void {
    this.saveContent();
  }

  private setOptions(): void {
    this.editorOptions = { theme: this.theme, language: this.language };
  }

  private saveContent(): void {
    this.save.emit(this.getValue());
  }
}
