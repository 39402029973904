import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'vle-scroll-container',
  templateUrl: './scroll-container.component.html',
  styleUrls: ['./scroll-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollContainerComponent {
  @Input() isThin? = false;
}
