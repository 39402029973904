import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AsteriskAlign, LabelAlign } from '../../models';

@Component({
  selector: 'vle-control-group',
  templateUrl: 'control-group.component.html',
  styleUrls: ['control-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlGroupComponent {
  AsteriskAlign = AsteriskAlign;

  @Input() labelText?: string;
  @Input() asteriskAlign?: AsteriskAlign;
  @Input() labelAlign?: LabelAlign = LabelAlign.Top;
  @Input() isError?: boolean = false;
}
