import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { BehaviorSubject, from, merge, Observable, of, Subject } from 'rxjs';
import { debounceTime, filter, finalize, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { TypeaheadItem } from '../models';

@Component({
  selector: 'vl-typeahead',
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VlTypeaheadComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() inputControl = new FormControl();
  @Input() itemsGetter: (value: string) => Promise<TypeaheadItem[]>;
  @Input() debounceTime: number;
  @Input() minChars: number = 1;
  @Input() isDropup = false;
  @Input() isOpenOnInit = false;
  @Input() isDisabled = false;
  @Input() minMenuWidth: number;
  @Input() iconClass: string;
  @Input() menuClass: string;
  @Input() filterResultsByInput = true;
  @Input() appendToBody = true;
  @Input() allowInsideClick = false;

  filteredItems$: Observable<TypeaheadItem[]>;
  isLoading$ = new BehaviorSubject(false);
  destroy$ = new Subject<void>();

  @ViewChild('input', { static: false }) private input: ElementRef;
  @ViewChild('dropdown', { static: true }) private dropdown: BsDropdownDirective;

  @Output() private itemSelect = new EventEmitter<TypeaheadItem>();

  ngOnInit(): void {
    this.filteredItems$ = merge(
      of(this.inputControl.value),
      this.inputControl.valueChanges.pipe(debounceTime(this.debounceTime || 200)),
      this.itemSelect.pipe(map(item => item.value))
    ).pipe(
      map(value => (value ? value.trim().toLowerCase() : '')),
      filter(value => !value || value.length >= this.minChars),
      tap(() => this.isLoading$.next(true)),
      switchMap(value => {
        return (!!value ? from(this.itemsGetter(value)) : of([])).pipe(
          map(items =>
            this.filterResultsByInput ? (items || []).filter(i => i.value.toLowerCase().includes(value)) : items
          ),
          finalize(() => this.isLoading$.next(false))
        );
      }),
      takeUntil(this.destroy$),
      shareReplay()
    );
  }

  ngAfterViewInit(): void {
    if (this.isOpenOnInit && this.input) {
      setTimeout(() => {
        this.input.nativeElement.click();
        this.input.nativeElement.focus();
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onInputClick(event: MouseEvent): void {
    if (this.dropdown.isOpen && event.target === document.activeElement) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  onItemSelect(item: TypeaheadItem): void {
    this.inputControl.setValue(item.value, { emitEvent: false });
    this.itemSelect.emit(item);
  }

  trackById(_: number, i: TypeaheadItem): TypeaheadItem['id'] {
    return i.id;
  }
}
