export enum ToastPosition {
  bottomRight = 'bottomRight',
  bottomLeft = 'bottomLeft',
  topRight = 'topRight',
  topLeft = 'topLeft',
  topCenter = 'topCenter',
  bottomCenter = 'bottomCenter'
}

export interface ToastSettings {
  animateInside?: boolean;
  class?: string;
  closeOnClick?: boolean;
  drag?: boolean;
  message?: string;
  position?: ToastPosition;
  progressBar?: boolean;
  target?: string;
  timeout?: number;
}

export interface Toaster {
  settings(settings: ToastSettings): void;

  show(settings: ToastSettings): void | boolean;
}
