import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonRole } from '../../models';

@Component({
  selector: 'vle-modal-base',
  templateUrl: './modal-base.component.html',
  styleUrls: ['./modal-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalBaseComponent {
  @Input() modalTitle?: string;
  @Input() isCloseButtonHidden: boolean;

  ButtonRole = ButtonRole;

  @Output() private modalClose = new EventEmitter<void>();

  onModalClose(): void {
    this.modalClose.emit();
  }
}
