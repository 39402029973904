import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateRangePipe'
})
export class DateRangePipe implements PipeTransform {
  datePipe = new DatePipe('en-US');

  transform(date: Date[] | Date, format: string): string {
    if (Array.isArray(date)) {
      return date.reduce((acc, item) => [...acc, this.datePipe.transform(item, format)], []).join(' - ');
    }

    return this.datePipe.transform(date, format);
  }
}
