import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[vleDropFile]'
})
export class DropFileTargetDirective {
  @HostBinding('class.fileover') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<FileList>();

  @HostListener('dragover', ['$event']) onDragOver(evt: any): void {
    evt.preventDefault();
    evt.stopPropagation();

    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(evt: any): void {
    evt.preventDefault();
    evt.stopPropagation();

    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) ondrop(evt: any): void {
    evt.preventDefault();
    evt.stopPropagation();

    this.fileOver = false;

    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}
