import { catchError, map, take } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { AddressSearchApi, AsteriskAlign, FeatureType, TypeaheadItem } from '../../models';
import { Coordinates, MapBoxContext, MapBoxSearchRequest } from './address-search.model';
import { AddressSearchService } from './address-search.service';

type ItemsGetter = (searchString: string) => Promise<TypeaheadItem[]>;

interface SelectedItemFeature<T> {
  context?: T;
  address?: string;
  text: string;
  center?: Coordinates;
  geometry: { type: string; coordinates: Coordinates };
}

@Component({
  selector: 'vle-address-search',
  templateUrl: './address-search.component.html',
  styleUrls: ['./address-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressSearchComponent implements OnChanges {
  @Input() asteriskAlign: AsteriskAlign;
  @Input() labelText: string;
  @Input() inputControl: FormControl = new FormControl('');
  @Input() countryCodes: string[] = []; // ISO 3166-1 alpha-2 country codes
  @Input() suggestionTypes: FeatureType[] = [];
  @Input() debounceTime: number = 200;
  @Input() minChars: number = 3;
  @Input() isDropup?: boolean;
  @Input() isDisabled?: boolean;
  @Input() searchApi: AddressSearchApi = AddressSearchApi.MAPBOX;
  @Input() apiKey: string;

  itemsGetter: ItemsGetter;

  @ViewChild('googleDetailsHelper') private googleDetailsHelper: ElementRef;

  @Output() private itemSelect = new EventEmitter<TypeaheadItem>();

  constructor(private addressSearchService: AddressSearchService) {}

  ngOnChanges(): void {
    this.itemsGetter = this.getItemsGetter();
  }

  onTypeAheadItemSelect(item: TypeaheadItem): void {
    switch (this.searchApi) {
      case AddressSearchApi.GOOGLE:
        this.handleItemSelectionGoogle(item);
        return;
      case AddressSearchApi.MAPBOX:
        this.handleItemSelectionMapBox(item);
        return;
    }
  }

  private getItemsGetter(): ItemsGetter {
    switch (this.searchApi) {
      case AddressSearchApi.GOOGLE:
        return this.searchItemsGoogle();
      case AddressSearchApi.MAPBOX:
        return this.searchItemsMapBox();
      default:
        return _ =>
          new Promise(resolve => {
            resolve([]);
          });
    }
  }

  private handleItemSelectionMapBox(item: TypeaheadItem): void {
    const { context, address, text, center, geometry } = item.data as SelectedItemFeature<MapBoxContext[]>;
    this.itemSelect.emit({
      ...item,
      data: {
        ...context.reduce((acc, ctx) => {
          const [ctxType] = ctx.id.split('.');
          return { ...acc, [ctxType]: ctx };
        }, {}),
        address: `${address ? `${address} ` : ''}${text || ''}`,
        coordinates: geometry ? geometry.coordinates : center
      }
    });
  }

  private handleItemSelectionGoogle(item: TypeaheadItem): void {
    this.addressSearchService
      .googlePlaceService(this.googleDetailsHelper.nativeElement, this.apiKey)
      .pipe(take(1))
      .subscribe(service => {
        const request = { placeId: item.id, fields: ['geometry.location', 'formatted_address', 'name'] };
        service.getDetails(request, result => {
          const location = result.geometry?.location;
          this.itemSelect.emit({
            ...item,
            data: {
              address: `${result.formatted_address ? `${result.formatted_address}` : ''}${result.name || ''}`,
              coordinates: location ? [location.lng, location.lat] : undefined
            }
          });
        });
      });
  }

  private searchItemsMapBox(): (searchString: string) => Promise<TypeaheadItem[]> {
    return (searchString: string): Promise<TypeaheadItem[]> => {
      const request: MapBoxSearchRequest = {
        input: searchString,
        country: this.countryCodes.length ? this.countryCodes.join(',') : undefined,
        types: this.suggestionTypes.length ? this.suggestionTypes.join(',') : undefined,
        limit: '10'
      };
      return this.addressSearchService
        .mapBoxPlacePredictions(request, this.apiKey)
        .pipe(
          map(res =>
            res.features.map(f => {
              return { id: f.id, value: f.place_name, displayValue: f.place_name, data: f };
            })
          ),
          catchError(() => [])
        )
        .toPromise();
    };
  }

  private searchItemsGoogle(): (searchString: string) => Promise<TypeaheadItem[]> {
    return (searchString: string): Promise<TypeaheadItem[]> => {
      const request = {
        input: searchString,
        types: this.suggestionTypes,
        componentRestrictions: this.countryCodes
          ? {
              country: this.countryCodes
            }
          : undefined
      };
      return this.addressSearchService
        .googlePlacePredictions(request, this.apiKey)
        .pipe(
          map(data => {
            return (
              data?.predictions.map(pred => {
                return { id: pred.place_id, value: pred.description, displayValue: pred.description, data: pred };
              }) || []
            );
          })
        )
        .toPromise();
    };
  }
}
