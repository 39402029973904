import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'vd-subtotal-cell-renderer',
  template: `
    <div class="vd-subtotal">SUBTOTAL</div>
  `,
  styleUrls: ['./subtotal-cell-renderer.component.scss']
})
export class DemoSubtotalCellRendererComponent implements ICellRendererAngularComp {
  agInit(_: ICellRendererParams): void {
    //
  }

  refresh(): boolean {
    return false;
  }
}
