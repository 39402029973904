import moment from 'moment';
import { BsDatepickerConfig, BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { AsteriskAlign, DatepickerPlacement } from '../../models';
import { DateRangePipe } from '../../pipes';

const convertDateToGmtZone = (date: Date): Date => {
  if (!date) return date;
  const timeZoneOffset = date.getTimezoneOffset();
  return moment(date).add(timeZoneOffset, 'minutes').toDate();
};

@Component({
  selector: 'vle-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatepickerComponent implements OnInit, OnChanges, AfterViewInit {
  @HostBinding('attr.datepicker-id') @Input() datepickerId: string;

  @Input() inputControl: FormControl;
  @Input() minDate: string | Date;
  @Input() maxDate: string | Date;
  @Input() minMode: 'day' | 'month' | 'year' = 'day';
  @Input() labelText?: string;
  @Input() dateFormat? = 'MM/dd/yyyy';
  @Input() isOpenOnInit? = false;
  @Input() isDisabled?: boolean;
  @Input() isHorizontal? = false;
  @Input() isValidationHidden?: boolean;
  @Input() isShowCurrentMonthDatesOnly? = false;
  @Input() isDaysWeeksHidden? = false;
  @Input() isInputEditable? = false;
  @Input() placement?: DatepickerPlacement;
  @Input() asteriskAlign?: AsteriskAlign;
  @Input() placeholder?: string = '';
  @Input() isPreventStopPropagation?: boolean = false;

  DatePipe = DatePipe;
  DateRangePipe = DateRangePipe;
  DatepickerPlacement = DatepickerPlacement;

  formControl = new FormControl();

  isRange: boolean;

  initialMomentDate: moment.Moment;
  initialDate: Date | Date[];
  isViewInit = false;

  isDatepickerVisible: boolean;
  datepickerConfig: Partial<BsDatepickerConfig> = {
    customTodayClass: 'bs-datepicker-today',
    showWeekNumbers: false,
    useUtc: true
  };
  @Output() private valueChange = new EventEmitter<Date | Date[]>();
  @Output() private toggle = new EventEmitter<boolean>();

  @ViewChild(BsDatepickerDirective) private datepicker: BsDatepickerDirective;

  constructor(private datePipe: DatePipe) {}

  ngOnInit(): void {
    let value = this.inputControl.value;
    this.isRange = Array.isArray(value);

    this.initialMomentDate = moment.utc(this.isRange ? value[0] : value);
    const date = this.initialMomentDate.toDate();
    this.initialDate = convertDateToGmtZone(date);

    if (this.isInputEditable && this.initialMomentDate.isValid()) {
      value = this.datePipe.transform(this.initialDate, this.dateFormat);
    }

    this.formControl.setValidators(this.inputControl.validator ? [this.inputControl.validator] : []);
    this.formControl.setValue(value);
    this.formControl.markAsTouched();

    this.datepickerConfig = {
      ...this.datepickerConfig,
      adaptivePosition: !this.placement,
      selectFromOtherMonth: !this.isShowCurrentMonthDatesOnly
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { placement, inputControl } = changes;
    if (placement && placement.currentValue !== placement.previousValue) {
      this.datepickerConfig = {
        ...this.datepickerConfig,
        adaptivePosition: !this.placement
      };
    }

    if (inputControl && !inputControl.firstChange && inputControl.currentValue !== inputControl.previousValue) {
      const date = moment.utc(inputControl.currentValue?.value).toDate();
      if (moment(date).isValid()) {
        const gmtDate = convertDateToGmtZone(date);
        this.datepicker._bsValue = gmtDate;
        if (this.dateFormat) {
          const formattedValue = this.datePipe.transform(gmtDate, this.dateFormat);
          this.formControl.setValue(formattedValue);
        } else {
          this.formControl.setValue(gmtDate);
        }
      }
    }
  }

  ngAfterViewInit(): void {
    this.isViewInit = true;
    if (this.isOpenOnInit && this.datepicker) {
      this.datepicker.show();
    }
  }

  onBlur(value: string): void {
    this.inputControl.setValue(value);

    if (value) {
      const date = moment.utc(value, this.dateFormat);

      this.valueChange.emit(date.toDate());
    }

    this.valueChange.emit();
  }

  onValueChange(date: Date | Date[]): void {
    if (!this.isViewInit) {
      return;
    }

    if (this.isInputEditable && !Array.isArray(date)) {
      const formattedValue = this.datePipe.transform(date, this.dateFormat);

      this.inputControl.setValue(formattedValue);
      this.formControl.setValue(formattedValue);
    } else {
      this.inputControl.setValue(date);
      this.formControl.setValue(date);
    }

    this.inputControl.markAsUntouched();
    this.formControl.markAsUntouched();

    this.valueChange.emit(date);
  }

  stopPropagation(event: MouseEvent): void {
    if (this.isPreventStopPropagation) {
      return;
    }
    event.stopPropagation();
  }

  onDatePickerClick(event: MouseEvent): void {
    if (this.isDisabled) {
      event.stopImmediatePropagation();
      event.preventDefault();
    }
  }

  onToggle(isOpened: boolean): void {
    setTimeout(() => {
      this.toggle.emit(isOpened);
    });
  }
}
