export enum Language {
  JS = 'javascript',
  HTML = 'html',
  CSS = 'css',
  MODEL = 'modelLang',
  JSON = 'json'
}

export enum Theme {
  DARK = 'vs-dark',
  LIGHT = 'vs'
}
