import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'vle-checkbox-cell-renderer',
  templateUrl: './checkbox-cell-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxCellRendererComponent implements ICellRendererAngularComp {
  value: boolean;
  isDisabled: boolean;
  params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    const { isDisabled } = params.colDef.cellRendererParams || {};

    this.params = params;
    this.value = params.value;
    this.isDisabled = typeof isDisabled === 'function' ? isDisabled(params) : isDisabled;
  }

  refresh(): boolean {
    return false;
  }

  onCheckedChange(event: Event): void {
    this.params.setValue((event.target as any).checked);
  }
}
