import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'vle-subtotal-cell-renderer',
  template: ` <div class="vle-subtotal">SUBTOTAL</div> `,
  styleUrls: ['./subtotal-cell-renderer.component.scss']
})
export class SubtotalCellRendererComponent implements ICellRendererAngularComp {
  agInit(_: ICellRendererParams): void {
    //
  }

  refresh(): boolean {
    return false;
  }
}
