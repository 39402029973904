import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';

interface TooltipData {
  cellContent: string;
  tooltipContent: string;
}

interface CellRendererParams {
  class: string;
  containerClass: string;
  container: string;
  placement: string;
  trigger: string;
}

@Component({
  selector: 'vd-tooltip-cell-renderer',
  templateUrl: './tooltip-cell-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DemoTooltipCellRendererComponent implements ICellRendererAngularComp {
  data: TooltipData;
  cellRendererParams: CellRendererParams;
  trigger: CellRendererParams['trigger'];

  constructor(private cdr: ChangeDetectorRef, private ngZone: NgZone) {}

  agInit(params: ICellRendererParams): void {
    this.setContents(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.setContents(params);
    this.cdr.detectChanges();

    return true;
  }

  setContents({ value, colDef }: ICellRendererParams): void {
    this.data = value;
    this.cellRendererParams = colDef?.cellRendererParams;
    this.trigger = colDef?.cellRendererParams?.trigger ?? 'hover';
  }

  onShown(_: any): void {
    this.ngZone.run(() => {
      setTimeout(() => {
        // https://veloce.atlassian.net/browse/CRQ-884
        // https://veloce.atlassian.net/browse/CRQ-996
        // this hack is needed to run extra change detection
        // because when component is used in runtime it doesn't always open
      }, 50);
    });
  }
}
