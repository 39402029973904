import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AsteriskAlign, LabelAlign } from '../../models';

@Component({
  selector: 'vle-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderComponent {
  @Input() isChecked: boolean;
  @Input() isDisabled: boolean;
  @Input() labelText: string;
  @Input() labelAlign?: LabelAlign = LabelAlign.Top;
  @Input() asteriskAlign: AsteriskAlign;

  @Output() private checkedChange = new EventEmitter<void>();

  onSlideClick(): void {
    if (!this.isDisabled) {
      this.checkedChange.emit();
    }
  }
}
