import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  Renderer2,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { AsteriskAlign, DropdownItem, ItemLikeConfig, LabelAlign, Trigger } from '../../models';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';

@Component({
  selector: 'vle-dropdown',
  styleUrls: ['dropdown.component.scss'],
  templateUrl: 'dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownComponent implements AfterViewChecked, OnDestroy {
  @ViewChild('dropdown', { read: BsDropdownDirective, static: false }) dropdown: BsDropdownDirective;
  @ViewChild('dropdownMenu', { static: false }) dropdownMenu: ElementRef;
  @ViewChild('toggleElement') toggleElement: ElementRef;

  @Input() menuItems: DropdownItem<unknown>[];
  @Input() autoClose = true;
  @Input() insideClick = false;
  @Input() dropup = false;
  @Input() appendToBody = true;
  @Input() isWide?: boolean;
  @Input() isDisabled?: boolean;
  @Input() dividerAt?: number;
  @Input() trigger? = Trigger.CLICK;
  @Input() dropdownId? = '';
  @Input() isRightAligned?: boolean;
  @Input() itemLikeConfig?: ItemLikeConfig;
  @Input() labelText?: string;
  @Input() asteriskAlign: AsteriskAlign;
  @Input() isHorizontal: boolean;
  @Input() autoDropup?: boolean = false;
  @Input() dropdownHeight?: number = 0;

  Trigger = Trigger;
  LabelAlign = LabelAlign;

  @Output() private handleMenuItemSelect = new EventEmitter<DropdownItem>();
  @Output() private toggleDropdown = new EventEmitter<boolean>();

  highlightedItemIndex = -1;
  highlightedOptionUpdated = false;
  searchChars = '';
  searchTimeoutHandle;
  listenerFn: () => void;

  constructor(private ngZone: NgZone, private renderer: Renderer2) {
    this.listenerFn = this.renderer.listen('window', 'click', (e: Event) => {
      if (
        this.toggleElement &&
        this.dropdownMenu &&
        !this.toggleElement.nativeElement.contains(e.target) &&
        !this.dropdownMenu.nativeElement.contains(e.target)
      ) {
        this.hide();
      }
    });
  }

  ngAfterViewChecked() {
    if (this.highlightedOptionUpdated) {
      const el = this.dropdownMenu?.nativeElement.querySelector('.vle-dropdown-menu__item--highlighted');
      el?.scrollIntoView({ block: 'nearest' });

      this.highlightedOptionUpdated = false;
    }
  }

  onMenuItemSelect(event: Event, item: DropdownItem<any>): void {
    event.stopPropagation();

    if (!item.isUnavailable) {
      this.hide();
      this.handleMenuItemSelect.emit(item);
    }
  }

  dropdownShownHandler(): void {
    this.ngZone.run(() => {
      setTimeout(() => {
        // https://veloce.atlassian.net/browse/CRQ-884
        // https://veloce.atlassian.net/browse/CRQ-996
        // this hack is needed to run extra change detection
        // because when component is used in runtime it doesn't always open
      }, 50);
    });
    this.toggleDropdown.emit(true);
  }

  dropdownHiddenHandler(): void {
    this.toggleDropdown.emit(false);
  }

  onToggleButtonClick(): void {
    if (this.dropdown.isOpen) {
      this.hide();
    } else {
      this.setDropdownPosition();
    }
  }

  show(): void {
    this.setDropdownPosition();
    this.highlightedItemIndex = -1;
  }

  hide(): void {
    this.dropdown.hide();
  }

  trackByDisplayValue(_: number, item: DropdownItem<any>): unknown {
    return item.displayValue;
  }

  get isOpen(): boolean {
    return this.dropdown?.isOpen;
  }

  onKeydown(event: KeyboardEvent): void {
    if (this.isDisabled || !this.menuItems?.length) {
      return;
    }

    switch (event.key) {
      case 'ArrowDown':
      case 'ArrowUp':
        if (!this.isOpen) {
          this.show();
        } else {
          const newIndex =
            event.key === 'ArrowDown'
              ? this.findNextEnabledOptionIndex(this.highlightedItemIndex)
              : this.findPrevEnabledOptionIndex(this.highlightedItemIndex);

          if (newIndex !== this.highlightedItemIndex) {
            this.highlightedItemIndex = newIndex;
            this.highlightedOptionUpdated = true;
          }
        }

        event.preventDefault();
        break;
      case 'Enter':
        if (this.isOpen && this.highlightedItemIndex >= 0) {
          this.onMenuItemSelect(event, this.menuItems[this.highlightedItemIndex]);
        }
      case 'Escape':
      case 'Tab':
        this.hide();
        break;
      default:
        if (!event.metaKey) {
          this.search(event);
        }
        break;
    }
  }

  private setDropdownPosition() {
    if (this.autoDropup) {
      const bounding = this.toggleElement.nativeElement.getBoundingClientRect();
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;
      const dropup = bounding.bottom + this.dropdownHeight > windowHeight;
      this.dropdown.dropup = dropup;
      this.dropdown.placement = `${dropup ? 'top' : 'bottom'} ${this.isRightAligned ? 'right' : 'left'}`;
    }
    this.dropdown.show();
  }

  private search(event: KeyboardEvent): void {
    this.searchChars += event.key;

    const searchIndex =
      this.highlightedItemIndex != null
        ? this.searchOptionIndexInRange(this.searchChars, this.highlightedItemIndex + 1, this.menuItems.length)
        : -1;
    const newIndex = this.searchOptionIndexInRange(this.searchChars, searchIndex, this.menuItems.length);

    this.highlightedItemIndex = newIndex;

    if (newIndex >= 0) {
      this.highlightedOptionUpdated = true;
    }

    clearTimeout(this.searchTimeoutHandle);
    this.searchTimeoutHandle = setTimeout(() => (this.searchChars = ''), 300);
  }

  private searchOptionIndexInRange(searchValue: string, start: number, end: number): number {
    return this.menuItems.findIndex(({ displayValue, isUnavailable }, index) => {
      return index >= start && index <= end && !isUnavailable && displayValue.toLowerCase().startsWith(searchValue);
    });
  }

  private findPrevEnabledOptionIndex(i: number): number {
    if (!this.menuItems.some(({ isUnavailable }) => !isUnavailable)) {
      return -1;
    }

    let nextIndex = i - 1;
    if (nextIndex < 0) {
      nextIndex = this.menuItems.length - 1;
    }

    if (this.menuItems[nextIndex].isUnavailable) {
      return this.findPrevEnabledOptionIndex(nextIndex);
    }
    return nextIndex;
  }

  private findNextEnabledOptionIndex(i: number): number {
    if (!this.menuItems.some(({ isUnavailable }) => !isUnavailable)) {
      return -1;
    }

    let nextIndex = i + 1;
    if (nextIndex >= this.menuItems.length) {
      nextIndex = 0;
    }

    if (this.menuItems[nextIndex].isUnavailable) {
      return this.findNextEnabledOptionIndex(nextIndex);
    }
    return nextIndex;
  }

  ngOnDestroy() {
    if (this.listenerFn) {
      this.listenerFn();
    }
  }
}
