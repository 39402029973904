import { DropdownItem } from './dropdown';

export enum FilterableType {
  DATE = 'DATE',
  TEXT = 'TEXT',
  LIST = 'LIST',
  INPUT = 'INPUT',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN'
}

export enum FilterableOp {
  EQ = 'EQ',
  NE = 'NE',
  LIKE = 'LIKE',
  GT = 'GT',
  GE = 'GE',
  LT = 'LT',
  LE = 'LE',
  SAME = 'SAME',
  EA = 'EA',
  EB = 'EB',
  AF = 'AF',
  BF = 'BF'
}

export interface Filterable {
  name: string;
  label: string;
  type: FilterableType;
  className?: string;
  isReadOnly?: boolean;
  isUnique?: boolean;
  hideConditionValue?: boolean;
  properties?: { [k: string]: any };
  iconName?: string;
  menuItems?: DropdownItem[];
}

export interface FilterCondition {
  key: string;
  operator: FilterableOp;
  value: string;
  displayValue?: string;
}

export class Filter {
  filterable: Filterable;
  condition: FilterCondition;

  constructor(filterable: Filterable) {
    this.filterable = filterable;
    const { isReadOnly, type, name } = filterable;

    if (type === FilterableType.BOOLEAN && isReadOnly) {
      this.condition = {
        key: name,
        operator: FilterableOp.EQ,
        value: 'true'
      };
    }
  }
}

export interface MenuFilter {
  buttonLabel: string;
  filterItems: Filterable[];
  className?: string;
}
