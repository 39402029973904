export type CommonFeatureType = 'country' | 'locality' | 'address';

export type MapBoxFeatureType =
  | CommonFeatureType
  | 'region'
  | 'postcode'
  | 'district'
  | 'place'
  | 'neighborhood'
  | 'poi';

/**
 * https://developers.google.com/maps/documentation/places/web-service/supported_types#table3
 */
export type GoogleFeatureType =
  | CommonFeatureType
  | 'establishment'
  | '(regions)'
  | 'postal_code'
  | 'geocode'
  | 'sublocality'
  | 'administrative_area_level_1'
  | 'administrative_area_level_2'
  | '(cities)';

export type FeatureType = MapBoxFeatureType | GoogleFeatureType;

export enum AddressSearchApi {
  GOOGLE = 'GOOGLE',
  MAPBOX = 'MAPBOX'
}
