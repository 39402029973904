import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vle-empty-page',
  templateUrl: 'empty-page.component.html',
  styleUrls: ['empty-page.component.scss']
})
export class EmptyPageComponent {
  @Input() label: string;
  @Input() description: string;
  @Input() addButtonText: string;

  @Output() private addButtonClick = new EventEmitter<void>();

  onAddButtonClick(): void {
    this.addButtonClick.emit();
  }
}
