import { DragulaService } from 'ng2-dragula';
import { Subject, Subscription, takeWhile } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ButtonRole, TemplatePropertyMap } from '../../../models';

enum TemplatePropertiesTab {
  List = 'List',
  Edit = 'Edit'
}

interface FormGroupValue {
  [key: string]: string;
}

@Component({
  selector: 'vle-template-properties',
  styleUrls: ['template-properties.component.scss'],
  templateUrl: 'template-properties.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplatePropertiesComponent implements AfterViewInit, OnDestroy {
  ButtonRole = ButtonRole;
  TemplatePropertiesTab = TemplatePropertiesTab;

  currentTemplatePropertiesTab = TemplatePropertiesTab.List;
  mappedControls: any[] = [];
  dragulaGroup: string = 'items';

  @Input() templateProperties: FormArray;
  @Input() trackByPropertyValueName: string;
  @Input() templatePropertyMap: TemplatePropertyMap = {};
  @Input() isPropertyFullScreen?: boolean;
  @Input() itemName: string = 'item';
  @Input() itemNamePlural: string = 'items';
  @Input() noItemsIconClass: string;
  @Input() isReorderEnabled: boolean;

  @Output() private togglePropertyExpand = new EventEmitter<void>();

  private _mirrorContainerRef: ElementRef;
  @ViewChild('mirrorContainerRef') set mirrorContainerRef(content: ElementRef) {
    this._mirrorContainerRef = content;
  }

  private selectedFormGroup: FormGroup;
  private editableFormGroup: FormGroup;
  private destroy$ = new Subject<void>();
  private editableFormStatusSub: Subscription;

  get items(): any[] {
    return this.templateProperties.value;
  }

  set items(value: any[]) {
    this.templateProperties.setValue(value);
  }

  constructor(private fb: FormBuilder, private dragulaService: DragulaService, private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    if (this.isReorderEnabled) {
      this.initDragulaService();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.isReorderEnabled) {
      this.dragulaService.destroy(this.dragulaGroup);
    }
  }

  onPropertyConfigure(index?: number): void {
    const selectedFormGroup = this.templateProperties.at(index) as FormGroup;
    const selectedFormGroupValue = this.getSelectedFormGroupValue(selectedFormGroup);
    const propertyRenderOrder = Object.keys(this.templatePropertyMap).reduce((trunk, key, i) => {
      return { ...trunk, [key]: (i + 1) * 10 };
    }, {});

    this.selectedFormGroup = selectedFormGroup;
    this.editableFormGroup = this.fb.group(
      Object.keys(selectedFormGroupValue).reduce(
        (trunk, key) => ({
          ...trunk,
          [key]: [
            selectedFormGroupValue[key],
            {
              updateOn: this.templatePropertyMap[key].updateOn,
              validators: this.templatePropertyMap[key].validators,
              asyncValidators: this.templatePropertyMap[key].asyncValidators
            }
          ]
        }),
        {}
      )
    );
    this.mappedControls = Object.keys(this.editableFormGroup.controls)
      .sort((keyA, keyB) => propertyRenderOrder[keyA] - propertyRenderOrder[keyB])
      .map(key => {
        const control = this.editableFormGroup.get(key);
        const mappedControl = {
          ...this.templatePropertyMap[key],
          control,
        };

        if (typeof this.templatePropertyMap[key].onInitMappedControls === 'function') {
          this.templatePropertyMap[key].onInitMappedControls(mappedControl, this.editableFormGroup);
        }

        return mappedControl;
      });
    this.currentTemplatePropertiesTab = TemplatePropertiesTab.Edit;
  }

  onPropertyDelete(index: number): void {
    this.templateProperties.removeAt(index);
  }

  onSaveClick() {
    this.editableFormGroup.markAllAsTouched();
    Object.values(this.editableFormGroup.controls).forEach(control => {
      control.markAsDirty();
      control.updateValueAndValidity();
    });
    this.editableFormStatusSub?.unsubscribe();
    if (this.editableFormGroup.status === 'PENDING') {
      this.editableFormStatusSub = this.editableFormGroup.statusChanges
        .pipe(
          takeWhile(status => status === 'PENDING', true),
          takeUntil(this.destroy$)
        )
        .subscribe(status => {
          if (status === 'VALID') {
            this.onPropertyUpsert();
          }
        });
    } else if (this.editableFormGroup.status === 'VALID') {
      this.onPropertyUpsert();
    }
  }

  onPropertyUpsert(): void {
    const editedValue = this.editableFormGroup.value;
    const patchedValue = Object.keys(editedValue).reduce((trunk, key) => {
      const isMappable = this.templatePropertyMap[key].mapToArray;
      const value = editedValue[key];
      const shouldBeMapped = isMappable && !Array.isArray(value);

      if (shouldBeMapped) {
        return {
          ...trunk,
          [key]: value?.split(',') || []
        };
      }

      return {
        ...trunk,
        [key]: value
      };
    }, {});

    if (!this.selectedFormGroup) {
      const fbMappedValue = Object.keys(patchedValue).reduce((trunk, key) => {
        const value = patchedValue[key];

        return {
          ...trunk,
          [key]: Array.isArray(value) ? [value] : value
        };
      }, {});

      this.templateProperties.push(this.fb.group(fbMappedValue));
    } else {
      const newValue = {
        ...(this.selectedFormGroup?.getRawValue() || {}),
        ...patchedValue
      }
      this.selectedFormGroup.setValue(newValue);
    }

    this.selectedFormGroup = undefined;
    this.currentTemplatePropertiesTab = TemplatePropertiesTab.List;

    if (this.isReorderEnabled) {
      this.dragulaService.destroy(this.dragulaGroup);
      this.initDragulaService();
    }
  }

  onTogglePropertyExpand(): void {
    this.togglePropertyExpand.emit();
  }

  trackByValue = (_: number, value: string): string => value;

  trackByPropertyValue =
    (key: string) =>
    (_: number, property: unknown): string =>
      property[key];

  get isHasItems(): boolean {
    return this.templateProperties?.value?.length > 0;
  }

  private initDragulaService(): void {
    setTimeout(() => {
      const dragulaOptions = {
        direction: 'vertical',
        mirrorContainer: this._mirrorContainerRef?.nativeElement,
        copy: true,
        copyItem: (item: any) => ({ ...item })
      };

      const group = this.dragulaService.find(this.dragulaGroup);
      if (group) {
        group.options = {
          ...group.options,
          ...dragulaOptions
        };
      } else {
        this.dragulaService.createGroup(this.dragulaGroup, dragulaOptions);
      }

      this.dragulaService
        .dropModel()
        .pipe(takeUntil(this.destroy$))
        .subscribe(({ targetModel: sortedDataItems }) => {
          this.items = sortedDataItems;
          this.cdr.markForCheck();
        });
    });
  }

  private getSelectedFormGroupValue(selectedFormGroup: FormGroup): FormGroupValue {
    if (selectedFormGroup) {
      return selectedFormGroup.value;
    }

    return Object.keys(this.templatePropertyMap).reduce(
      (trunk, key) => ({
        ...trunk,
        [key]: null
      }),
      {}
    );
  }

  get visibleTemplateColumns(): string[] {
    return Object.keys(this.templatePropertyMap)
      .filter((key: string) => {
        return this.templatePropertyMap[key].isVisibleInTableView;
      })
      .sort((keyA, keyB) => this.templatePropertyMap[keyA].tableOrder - this.templatePropertyMap[keyB].tableOrder);
  }
}
