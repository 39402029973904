import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormArray, FormGroup, Validators } from '@angular/forms';
import {
  ButtonRole,
  DocumentTemplateSettingType,
  Language,
  TemplatePropertyMap,
  TemplatePropertyMapItem,
  TemplateSaveEmitPaylaod,
  Theme
} from '../../../models';
import { ErrorLevel, requiredValidator } from '../../../validators/validators.utils';
import { requiredAttributeValueValidator } from '../template-admin.utils';
import { TemplateAdminValidationService } from '../template-admin-validation.service';
import { combineLatest, distinctUntilChanged, pairwise, startWith, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

enum TemplateEditTab {
  Settings = 'Settings',
  Script = 'Script',
  Queries = 'Queries',
  Attributes = 'Attributes',
  AttributeValues = 'Attribute Values'
}

@Component({
  selector: 'vle-template-edit',
  styleUrls: ['template-edit.component.scss'],
  templateUrl: 'template-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TemplateAdminValidationService]
})
export class TemplateEditComponent implements OnInit, OnDestroy {
  TemplateEditTab = TemplateEditTab;
  DocumentTemplateSettingType = DocumentTemplateSettingType;
  ButtonRole = ButtonRole;

  currentTemplateEditTab = TemplateEditTab.Settings;
  templateEditTabs = Object.values(TemplateEditTab);
  templateQueryPropertyMap: TemplatePropertyMap = {
    queryName: {
      controlType: DocumentTemplateSettingType.TEXT,
      controlLabel: 'Name',
      isVisibleInTableView: true,
      tableOrder: 10,
      validators: [requiredValidator(ErrorLevel.ERROR, 'Complete this field.')]
    },
    resultObjectName: {
      controlType: DocumentTemplateSettingType.TEXT,
      controlLabel: 'Result object name',
      isVisibleInTableView: true,
      tableOrder: 20,
      validators: [requiredValidator(ErrorLevel.ERROR, 'Complete this field.')]
    },
    objectName: {
      controlType: DocumentTemplateSettingType.TEXT,
      controlLabel: 'Object name',
      isVisibleInTableView: true,
      tableOrder: 30,
      validators: [requiredValidator(ErrorLevel.ERROR, 'Complete this field.')],
      asyncValidators: [this.tmplAdminValidationService.objectNameExists()],
      updateOn: 'blur'
    },
    fields: {
      controlType: DocumentTemplateSettingType.TEXT,
      controlLabel: 'Fields',
      mapToArray: true,
      asyncValidators: [this.tmplAdminValidationService.objectFieldsExists()],
      updateOn: 'blur'
    },
    statement: {
      controlType: 'CODE-EDITOR' as DocumentTemplateSettingType,
      controlLabel: 'Statement'
    }
  };
  templateAttributePropertyMap: TemplatePropertyMap = {
    type: {
      controlType: DocumentTemplateSettingType.SELECT,
      controlLabel: 'Type',
      controlLov: Object.values(DocumentTemplateSettingType),
      isVisibleInTableView: true,
      tableOrder: 10,
      validators: [requiredValidator(ErrorLevel.ERROR, 'Complete this field.')]
    },
    name: {
      controlType: DocumentTemplateSettingType.TEXT,
      controlLabel: 'Name',
      isVisibleInTableView: true,
      tableOrder: 20,
      validators: [requiredValidator(ErrorLevel.ERROR, 'Complete this field.')]
    },
    label: {
      controlType: DocumentTemplateSettingType.TEXT,
      controlLabel: 'Label',
      validators: [requiredValidator(ErrorLevel.ERROR, 'Complete this field.')]
    },
    lov: {
      controlType: DocumentTemplateSettingType.TEXT,
      controlLabel: 'Values',
      mapToArray: true,
      validators: [Validators.required],
      onInitMappedControls: (mappedControl, form) => {
        const typeControl = form.controls.type;
        const valuesControl = mappedControl.control;

        this.enableDisableValuesControl(valuesControl, typeControl);
      }
    },
    value: {
      controlType: DocumentTemplateSettingType.TEXT,
      controlLabel: 'Default value',
      isVisibleInTableView: true,
      tableOrder: 30,
      validators: [requiredAttributeValueValidator()],
      onInitMappedControls: (mappedControl, form) => {
        const typeControl = form.controls.type;
        const valuesControl = form.controls.lov;
        const defaultValueControl = mappedControl.control;

        this.resetDefaultValueControl(defaultValueControl, typeControl);
        this.setDefaultValueControlTypeToMappedControl(mappedControl, typeControl, valuesControl);
      }
    },
    isTechnical: {
      controlType: DocumentTemplateSettingType.CHECKBOX,
      controlLabel: 'Technical'
    }
  };

  templateProperties: FormArray;
  isFullScreen: boolean;

  Language = Language;
  Theme = Theme;

  private destroy$ = new Subject<void>();

  @Input() form: FormGroup;
  @Input() isModalCloseButtonHidden: boolean;

  @Output() private viewCancel = new EventEmitter<void>();
  @Output() private viewBack = new EventEmitter<void>();
  @Output() private templateDelete = new EventEmitter<string>();
  @Output() private templateSave = new EventEmitter<TemplateSaveEmitPaylaod>();
  @Output() private templateValidate = new EventEmitter<void>();
  @Output() private templateClone = new EventEmitter<{ templateId: string; name: string }>();
  @Output() private downloadTemplate = new EventEmitter<string>();

  constructor(private tmplAdminValidationService: TemplateAdminValidationService) {}

  ngOnInit(): void {
    this.templateProperties = this.form.get(['template', 'properties']) as FormArray;
  }

  onViewCancel(): void {
    this.viewCancel.emit();
  }

  onViewBack(): void {
    this.viewBack.emit();
  }

  onTemplateEditTabSelect(tab: TemplateEditTab): void {
    this.isFullScreen = false;
    this.currentTemplateEditTab = tab;
  }

  onTemplateDelete(templateId: string): void {
    this.templateDelete.emit(templateId);
  }

  onTemplateSave(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      const templateGroup = this.form.get('template') as FormGroup;
      Object.values(templateGroup.controls).forEach(control => {
        control.markAsDirty();
        control.updateValueAndValidity();
      });
      return;
    }
    this.templateSave.emit(this.form.value);
  }

  onTemplateValidate(): void {
    this.templateValidate.emit();
  }

  onFileSelected(file: File): void {
    const { template } = this.form.value;

    this.form.patchValue({
      file,
      template: {
        ...template,
        fileName: file.name,
        fileId: undefined
      }
    });

    this.form.markAsTouched();
  }

  onToggleFulleScreen(): void {
    this.isFullScreen = !this.isFullScreen;
  }

  onTemplateClone(): void {
    const { id, name } = this.form.value.template;
    this.templateClone.emit({ templateId: id, name });
  }

  onDownloadTemplate(): void {
    this.downloadTemplate.emit(this.form.value.template.id);
  }

  onScriptChange(script: string): void {
    this.form.get(['template', 'script']).patchValue(script);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get fileNameControlError(): string {
    if (this.form.touched) {
      if (this.form.get('template.fileName')?.errors) {
        return 'Upload a template file.'
      }
  
      if (this.form.get('file')?.errors) {
        return 'Only .docx files are allowed'
      }
    }
    
    return '';
  }

  private resetDefaultValueControl(defaultValueControl: AbstractControl, typeControl: AbstractControl) {
    typeControl.valueChanges
      .pipe(startWith(typeControl.value), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(() => defaultValueControl.reset());
  }

  private setDefaultValueControlTypeToMappedControl(
    mappedControl: TemplatePropertyMapItem & { control: AbstractControl },
    typeControl: AbstractControl,
    valuesControl: AbstractControl
  ) {
    const typeControlValues = typeControl.valueChanges.pipe(startWith(typeControl.value));
    const valuesControlValues = valuesControl.valueChanges.pipe(startWith(valuesControl.value));

    combineLatest([valuesControlValues, typeControlValues])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([values, type]) => {
        if ([DocumentTemplateSettingType.SELECT, DocumentTemplateSettingType.RADIO].includes(type)) {
          mappedControl.controlType = DocumentTemplateSettingType.SELECT;

          if (values) {
            mappedControl.controlLov = (typeof values === 'string' ? values.split(',') : values).map(value =>
              value.trim()
            );
          }
          return;
        }

        mappedControl.controlLov = null;

        if (type === DocumentTemplateSettingType.CHECKBOX) {
          mappedControl.controlType = DocumentTemplateSettingType.CHECKBOX;
          return;
        }

        if (type === DocumentTemplateSettingType.DATE) {
          mappedControl.controlType = DocumentTemplateSettingType.DATE;
          return;
        }

        mappedControl.controlType = DocumentTemplateSettingType.TEXT;
      });
  }

  private enableDisableValuesControl(valuesControl: AbstractControl, typeControl: AbstractControl) {
    const disabledForTypes = [
      DocumentTemplateSettingType.TEXT,
      DocumentTemplateSettingType.CHECKBOX,
      DocumentTemplateSettingType.DATE
    ];

    typeControl.valueChanges.pipe(startWith(typeControl.value), takeUntil(this.destroy$)).subscribe(value => {
      // <vle-input-group> set a control enabled on init :(
      // It helps to disable the control for a case when control has initial data
      setTimeout(() => {
        if (disabledForTypes.includes(value)) {
          valuesControl.disable();
          valuesControl.reset();
        } else {
          valuesControl.enable();
        }
      });
    });
  }
}
