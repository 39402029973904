import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'vle-selected-checkbox-cell-renderer',
  templateUrl: 'selection-checkbox-cell-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectionCheckboxCellRendererComponent implements ICellRendererAngularComp {
  needRender: boolean;
  isDisabled: boolean;
  isRadio: boolean;
  params: ICellRendererParams;

  get value(): boolean {
    const isSelectedFn = this.params.context.componentParent.tableConfig?.isInternallySelected;

    return (isSelectedFn && isSelectedFn(this.params)) || this.params.node.isSelected();
  }

  agInit(params: ICellRendererParams): void {
    this.isRadio =
      !(params.colDef as any).headerCheckboxSelection() && !(params.colDef as any).cellEditorParams.hideSelectAll;
    this.params = params;
    this.needRender = !params.data.parentId && !!params.context.componentParent.tableConfig?.isSelectionDisabled;

    const { isSelectionDisabled } = params.context.componentParent.tableConfig;

    this.isDisabled = typeof isSelectionDisabled === 'function' ? isSelectionDisabled(params) : isSelectionDisabled;
  }

  refresh(): boolean {
    return false;
  }

  onCheckedChange(event: Event): void {
    this.params.node.setSelected((event.target as any).checked);
  }

  onSelect(_: string): void {
    this.params.node.setSelected(true);
  }
}
