import { FormControl, ValidatorFn } from '@angular/forms';
import { ErrorLevel } from '../../validators/validators.utils';

export function requiredAttributeValueValidator(): ValidatorFn {
  return ({ value, parent }: FormControl) => {
    if (
      (parent?.value.type === 'SELECT' || parent?.value.type === 'RADIO') &&
      (value === void 0 || value === null || value.trim() === '')
    ) {
      return { [ErrorLevel.ERROR]: { message: 'Complete this field.' } };
    }

    return null;
  };
}
