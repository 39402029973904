import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'vd-checkbox-cell-renderer',
  templateUrl: 'checkbox-cell-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DemoCheckboxCellRendererComponent implements ICellRendererAngularComp {
  value: boolean;
  isDisabled: boolean;
  params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.value = params.value;
    this.isDisabled = params.colDef.cellRendererParams?.isDisabled;
  }

  refresh(): boolean {
    return false;
  }

  onCheckedChange(event: Event): void {
    this.params.setValue((event.target as any).checked);
  }
}
