import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  ButtonRole,
  DocumentAttachment,
  DocumentTemplate,
  Language,
  TemplateAction,
  TemplateProperty,
  TemplateSaveEmitPaylaod,
  Theme
} from '../../models';
import { ErrorLevel, MimeType, mimeTypeValidator, requiredValidator } from '../../validators/validators.utils';

enum TemplateView {
  TemplateList = 'TemplateList',
  TemplateListAdmin = 'TemplateListAdmin',
  TemplateEdit = 'TemplateEdit',
  TemplateValidateView = 'TemplateValidateView'
}

interface TemplateActionPayload {
  actionName: string;
  template: DocumentTemplate;
}

interface TemplateAlterEmitPaylaod {
  templateId: string;
  name?: string;
  resolve: () => void;
}

@Component({
  selector: 'vle-template-admin',
  styleUrls: ['template-admin.component.scss'],
  templateUrl: 'template-admin.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateAdminComponent implements OnInit, OnChanges {
  public templatesFiltered: DocumentTemplate[] = [];

  @Input() set templates(_templates: DocumentTemplate[]) {
    this.templatesFiltered = _templates?.filter(
      ({ fileExtension }: DocumentTemplate) => fileExtension?.toLowerCase() === 'docx'
    );
  }

  @Input() templateProperties: { [key: string]: TemplateProperty } = {};
  @Input() generateDocumentActions: TemplateAction[] = [];
  public attachmentsFiltered: DocumentAttachment[] = [];

  @Input() set attachments(_attachments: DocumentAttachment[]) {
    this.attachmentsFiltered = _attachments?.filter(
      ({ fileExtension }: DocumentAttachment) => fileExtension?.toLowerCase() === 'pdf'
    );
  }

  @Input() isAdminModeEnabled: boolean;
  @Input() templateValidateAction?: (...args: unknown[]) => Promise<unknown>;
  @Input() isModalCloseButtonHidden: boolean;
  @Input() isCancelButtonHidden: boolean;

  TemplateView = TemplateView;
  ButtonRole = ButtonRole;
  currentView = TemplateView.TemplateList;

  form: FormGroup;
  templateEntities: { [key: string]: DocumentTemplate };

  Language = Language;
  Theme = Theme;
  templateValidateOutputData: unknown;

  @Output() private adminClose = new EventEmitter<void>();
  @Output() private templateAction = new EventEmitter<TemplateActionPayload>();
  @Output() private templateDelete = new EventEmitter<TemplateAlterEmitPaylaod>();
  @Output() private templateSave = new EventEmitter<TemplateSaveEmitPaylaod>();
  @Output() private templateValidate = new EventEmitter<DocumentTemplate>();
  @Output() private templateClone = new EventEmitter<TemplateAlterEmitPaylaod>();
  @Output() private attachmentAdd = new EventEmitter<any>();
  @Output() private attachmentRemove = new EventEmitter<DocumentAttachment>();
  @Output() private downloadTemplate = new EventEmitter<string>();

  private templateDraft: DocumentTemplate = {
    id: undefined,
    fileId: undefined,
    fileName: undefined,
    name: undefined,
    script: undefined,
    queries: [],
    properties: []
  };

  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      template: null,
      file: [null, mimeTypeValidator([MimeType.DOCX])]
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { attachments, templates } = changes;

    if (templates?.currentValue) {
      this.templateEntities = this.templatesFiltered.reduce((trunk, template) => {
        return {
          ...trunk,
          [template.id]: this.mergeProperties(template, this.templateProperties)
        };
      }, {});
    }

    if (attachments && !attachments.firstChange && attachments.previousValue !== attachments.currentValue) {
      const { id } = this.form.controls.template.value ?? {};

      this.onTemplateSelect(id, this.currentView);
      this.cdr.markForCheck();
    }
  }

  onViewCancel(view: TemplateView): void {
    if (view === TemplateView.TemplateListAdmin || view === TemplateView.TemplateEdit) {
      return this.onViewChange(TemplateView.TemplateList);
    }

    return this.onAdminClose();
  }

  onAdminClose(): void {
    this.adminClose.emit();
  }

  onViewChange(view: TemplateView): void {
    this.currentView = view;
  }

  onTemplateSelect(templateId: string, view: TemplateView): void {
    const template = this.templateEntities[templateId] || this.templateDraft;
    const { id, name, fileId, fileName, properties, queries, script } = template;

    this.form.setControl(
      'template',
      this.fb.group({
        id,
        name: [name, requiredValidator(ErrorLevel.ERROR, 'Complete this field.')],
        fileId,
        fileName: [fileName, Validators.required],
        properties: this.fb.array(
          properties?.map(({ name: propertyName, label, value, type, isTechnical, lov }) =>
            this.fb.group({
              name: propertyName,
              label,
              value,
              type,
              isTechnical: isTechnical && 'true',
              lov: [lov]
            })
          ) || []
        ),
        queries: this.fb.array(
          queries?.map(({ fields, objectName, queryName, resultObjectName, statement }) =>
            this.fb.group({
              fields: [fields],
              objectName,
              queryName,
              resultObjectName,
              statement
            })
          ) || []
        ),
        script,
        attachments: this.fb.array(this.attachmentsFiltered?.map(attachment => this.fb.control(attachment)) || [])
      })
    );
    this.onViewChange(view);
  }

  onTemplateAction(actionName: string): void {
    const { template } = this.form.value;

    this.templateAction.emit({
      actionName,
      template
    });
  }

  onTemplateDelete(templateId: string): void {
    this.templateDelete.emit({
      templateId,
      resolve: () => this.onViewChange(TemplateView.TemplateListAdmin)
    });
  }

  onTemplateSave(templatePayload: TemplateSaveEmitPaylaod): void {
    this.templateSave.emit({
      ...templatePayload,
      resolve: () => this.onViewChange(TemplateView.TemplateListAdmin)
    });
  }

  onTemplateClone({ templateId, name }: { templateId: string; name: string; }): void {
    this.templateClone.emit({
      templateId,
      name,
      resolve: () => this.onViewChange(TemplateView.TemplateListAdmin)
    });
  }

  onDownloadTemplate(id: string): void {
    this.downloadTemplate.emit(id);
  }

  onTemplateValidate(): void {
    if (this.templateValidateAction) {
      this.templateValidateAction(this.form.value.template).then(data => {
        this.templateValidateOutputData = typeof data === 'object' ? JSON.stringify(data, undefined, 2) : data;
        this.onViewChange(TemplateView.TemplateValidateView);
      });
    }

    if (this.templateValidate) {
      this.templateValidate.emit(this.form.value.template);
    }
  }

  onAttachmentAdd(attachment: any): void {
    this.attachmentAdd.emit(attachment);
  }

  onAttachmentRemove(documentAttachment: DocumentAttachment): void {
    this.attachmentRemove.emit(documentAttachment);
  }

  private mergeProperties(
    template: DocumentTemplate,
    templateProperties: { [key: string]: TemplateProperty }
  ): DocumentTemplate {
    return {
      ...template,
      properties: template.properties.map(property => templateProperties[property.name] || property)
    };
  }
}
