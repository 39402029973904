import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'vd-comment-cell-renderer',
  template: `<div>{{ comment }}</div>`,
  styleUrls: ['./comment-cell-renderer.component.scss']
})
export class DemoCommentCellRendererComponent implements ICellRendererAngularComp {
  comment: string;

  agInit({ data }: ICellRendererParams): void {
    this.comment = data.text;
  }

  refresh(): boolean {
    return false;
  }
}
