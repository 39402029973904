import {
  CellClassParams,
  CellValueChangedEvent,
  ColDef,
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellEditorParams,
  ICellRendererParams,
  IsColumnFuncParams,
  RowDragEvent,
  RowNode,
  ValueGetterParams
} from 'ag-grid-community';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { DemoTextCellEditorComponent } from './editors/text-cell-editor/text-cell-editor.component';
import { DemoCheckboxCellRendererComponent } from './renderers/checkbox-cell-renderer/checkbox-cell-renderer.component';
import { DemoCommentCellRendererComponent } from './renderers/comment-cell-renderer/comment-cell-renderer.component';
import { DemoContentCellRenderComponent } from './renderers/content-renderer/content-renderer.component';
import { DemoMenuRendererComponent } from './renderers/menu-renderer/menu-renderer.component';
import { DemoSubtotalCellRendererComponent } from './renderers/subtotal-cell-renderer/subtotal-cell-renderer.component';
import { DemoTooltipCellRendererComponent } from './renderers/tooltip-cell-renderer/tooltip-cell-renderer.component';
import { CommentRow, ErrorLevel, MenuItemEvent, MenuItemEventType, ProductRow, RowMenuConfig, RowType, TableRow } from '../../models';

interface TableConfig {
  isEnableMenu?: boolean;
  isEnableDrag?: boolean;
  isEnableSelect?: boolean;
  isEnableMultiRowDragging?: boolean;
}

const generateId = () => Date.now().toString();

const isCellEditable = (params: CellClassParams | ICellRendererParams | ICellEditorParams) => {
  const { editable } = params.colDef;
  return typeof editable === 'function' ? editable(params as IsColumnFuncParams) : !!editable;
};

@Component({
  selector: 'vd-table',
  templateUrl: './demo-table.component.html',
  styleUrls: ['./demo-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DemoTableComponent implements OnInit, OnChanges {
  @Input() gridOptions: Partial<GridOptions>;
  @Input() columnDefs: ColDef[] = [];
  @Input() rowData: TableRow[];
  @Input() tableConfig: TableConfig = {};
  @Input() rowMenuConfigGetter: (params: ValueGetterParams) => RowMenuConfig;
  @Input() rowClassRules: GridOptions['rowClassRules'] = {};

  colDefs: ColDef[] = [];
  indexedRowData: TableRow[] = [];

  context: any = { componentParent: this };

  _gridOptions: GridOptions;

  @Output() private tableReady = new EventEmitter<DemoTableComponent>();
  @Output() private selectionChanged = new EventEmitter<any[]>();
  @Output() private itemDragged = new EventEmitter<string[]>();
  @Output() private itemEdited = new EventEmitter<any>();
  @Output() private itemCustomEvent = new EventEmitter<any>();
  @Output() private itemRemoved = new EventEmitter<any>();
  @Output() private itemCloned = new EventEmitter<any>();
  @Output() private subtotalAdded = new EventEmitter<{ rowIndex: number }>();

  private gridApi: GridApi;
  private gridColumnApi: ColumnApi;

  ngOnInit(): void {
    this._gridOptions = {
      columnTypes: {
        currency: {
          valueFormatter: valueFormatterParams => {
            try {
              const { value, colDef } = valueFormatterParams;
              const digitsInfo = colDef?.cellRendererParams?.digitsInfo || '1.2-2';

              return new CurrencyPipe('en-US').transform(value, 'USD', 'symbol', digitsInfo);
            } catch (e) {
              return void 0;
            }
          }
        },
        float: {
          valueFormatter: ({ value }) => {
            try {
              return new DecimalPipe('en-US').transform(value, '1.2-2');
            } catch (e) {
              return void 0;
            }
          }
        },
        internal: {
          editable: false,
          suppressNavigable: true,
          suppressSizeToFit: true
        },
        special: {
          cellRendererSelector: ({ data }) => {
            switch (data.rowType) {
              case RowType.COMMENT:
                return { component: 'commentCellRenderer' };
              case RowType.SUBTOTAL:
                return { component: 'subtotalCellRenderer' };
              default:
                return null;
            }
          },
          valueSetter: ({ data, colDef, newValue }) => {
            if (data.rowType === RowType.COMMENT) {
              data.text = newValue;
            } else {
              data[colDef.field] = newValue;
            }

            return true;
          },
          cellClass: ({ data }) => {
            switch (data.rowType) {
              case RowType.COMMENT:
                return 'vd-cell-comment';
              case RowType.SUBTOTAL:
                return 'vd-cell-subtotal';
              default:
                return void 0;
            }
          }
        }
      },
      defaultColDef: {
        resizable: false,
        suppressMovable: true,
        suppressNavigable: ({ data }) => data.rowType === RowType.SUBTOTAL,
        rowDragText: () => '',
        cellStyle: ({ colDef }) => {
          if (colDef && colDef.autoHeight) {
            return { 'white-space': 'pre-wrap' };
          }

          return void 0;
        },
        cellRendererSelector: params => {
          switch (params.colDef.cellRendererParams?.type) {
            case 'menu':
              return { component: 'menuCellRenderer' };
            case 'custom':
              return { component: 'contentCellRenderer' };
            case 'checkbox':
              return { component: 'checkboxCellRenderer' };
            case 'tooltip':
              return { component: 'tooltipCellRenderer' };
            default:
              return null;
          }
        },
        cellEditorSelector: params => {
          const { colDef, data } = params;
          let { cellEditorParams } = colDef;
          const parameters: any = {};

          cellEditorParams = typeof cellEditorParams === 'function' ? cellEditorParams(params) : cellEditorParams;

          if (data.rowType !== RowType.PRODUCT) {
            parameters.skipValidation = true;
          }

          return {
            component:
              cellEditorParams && cellEditorParams.type === 'select' ? 'agSelectCellEditor' : 'agTextCellEditor',
            params: parameters
          };
        },
        cellClassRules: {
          'vd-cell-not-navigable': (params: CellClassParams) => {
            const { suppressNavigable } = params.colDef;

            if (typeof suppressNavigable === 'function') {
              return suppressNavigable(params as any);
            } else {
              return !!suppressNavigable;
            }
          },
          'vd-cell--error': ({ data, colDef }: CellClassParams) => {
            const validation = data.validation && data.validation[colDef.field];
            const errors = validation && validation[ErrorLevel.ERROR];

            return errors && errors.length;
          },
          'vd-cell--warning': ({ data, colDef }: CellClassParams) => {
            const validation = data.validation && data.validation[colDef.field];
            const errors = validation && validation[ErrorLevel.ERROR];
            const warnings = validation && validation[ErrorLevel.WARNING];

            return (!errors || !errors.length) && warnings && warnings.length;
          },
          'vd-cell-checkbox': ({ colDef }: CellClassParams) => {
            return colDef.cellRendererParams?.type === 'checkbox';
          },
          'vd-cell-tooltip': ({ colDef }: CellClassParams) => {
            return colDef.cellRendererParams?.type === 'tooltip';
          },
          'vd-cell-dropdown': (params: CellClassParams) => {
            const { colDef } = params;
            const cellEditorParams =
              typeof colDef.cellEditorParams === 'function' ? colDef.cellEditorParams(params) : colDef.cellEditorParams;
            return cellEditorParams?.type === 'dropdown' && isCellEditable(params);
          },
          'vd-cell-disabled': (params: CellClassParams) => {
            const { colDef } = params;
            const cellEditorParams =
              typeof colDef.cellEditorParams === 'function' ? colDef.cellEditorParams(params) : colDef.cellEditorParams;

            return typeof cellEditorParams?.isDisabled === 'function'
              ? cellEditorParams.isDisabled(params)
              : cellEditorParams?.isDisabled;
          }
        }
      },
      immutableData: true,
      frameworkComponents: {
        agTextCellEditor: DemoTextCellEditorComponent,
        checkboxCellRenderer: DemoCheckboxCellRendererComponent,
        commentCellRenderer: DemoCommentCellRendererComponent,
        contentCellRenderer: DemoContentCellRenderComponent,
        menuCellRenderer: DemoMenuRendererComponent,
        subtotalCellRenderer: DemoSubtotalCellRendererComponent,
        tooltipCellRenderer: DemoTooltipCellRendererComponent
      },
      rowBuffer: 0,
      rowClassRules: {
        ...this.rowClassRules,
        'vd-table-row--subtotal': ({ data }) => data.rowType === RowType.SUBTOTAL,
        'vd-table-row--empty': ({ data }) => data.rowType === RowType.EMPTY
      },
      rowSelection: 'multiple',
      enableMultiRowDragging: this.tableConfig.isEnableDrag && this.tableConfig.isEnableMultiRowDragging,
      suppressChangeDetection: true,
      suppressRowClickSelection: true,
      onCellValueChanged: this.onCellValueChanged.bind(this),
      onGridReady: this.onGridReady.bind(this),
      rowDragManaged: true,
      animateRows: true,
      onRowDragEnd: this.onRowDragEnd.bind(this),
      onRowDragLeave: this.onRowDragEnd.bind(this),
      onSelectionChanged: this.onSelectionChanged.bind(this),
      getRowNodeId: ({ id }: TableRow) => id,
      rowHeight: 34,
      singleClickEdit: true,
      isRowSelectable: ({ data }) => data && data.rowType !== RowType.EMPTY,
      ...this.gridOptions
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { columnDefs, rowData } = changes;

    // if (rowData && rowData.currentValue !== rowData.previousValue && !rowData.isFirstChange()) {
    // this.recalculateRowHeights();
    // }

    if (columnDefs && columnDefs.currentValue !== columnDefs.previousValue) {
      this.patchColumnDefs(columnDefs.currentValue);

      // when columns get hidden or shown row heights can change due to changing column widths
      if (!columnDefs.firstChange) {
        if (this.gridApi) {
          this.gridApi.stopEditing(true);
        }

        this.recalculateRowHeights(true);
      }
    }

    if (rowData) {
      this.indexedRowData = rowData.currentValue?.length
        ? rowData.currentValue.map((row: TableRow, index: number) => ({ ...row, index }))
        : [{ rowType: RowType.EMPTY }];

      const selectedRowsIds = this.indexedRowData.filter(({ isSelected }) => isSelected).map(({ id }) => id);

      if (this.gridApi) {
        // due to singleClickEdit: true option need to stop editing for cases when entering value in one cell
        // populates value in the cell on the right but user automatically enters editing mode
        // in the cell on the right by hitting TAB key
        this.gridApi.stopEditing(true);

        this.gridApi.setRowData(this.indexedRowData);

        const specialRowNodes = [];

        this.forEachNode(rowNode => {
          this.setSelected(rowNode, selectedRowsIds);

          if (rowNode.data?.rowType !== RowType.PRODUCT) {
            specialRowNodes.push(rowNode);
          }
        });

        if (specialRowNodes.length) {
          this.gridApi.redrawRows({ rowNodes: specialRowNodes });
        }
      }
    }
  }

  onGridReady(event: GridReadyEvent): void {
    const rowData = this.rowData || [];
    const selectedRowsIds = rowData.filter(({ isSelected }) => isSelected).map(({ id }) => id);

    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;

    this.forEachNode(rowNode => {
      this.setSelected(rowNode, selectedRowsIds);
    });

    this.recalculateRowHeights();

    this.tableReady.emit(this);
  }

  onSelectionChanged(): void {
    this.selectionChanged.emit(this.gridApi.getSelectedRows().map(({ id, rowType }) => ({ id, rowType })));
  }

  onRowDragEnd({ api, node, overIndex }: RowDragEvent): void {
    if (node.data.index !== overIndex) {
      const orderedIds: string[] = [];

      api.forEachNode(({ id }) => {
        orderedIds.push(id);
      });

      this.itemDragged.emit(orderedIds);
    }
  }

  recalculateRowHeights(force: boolean = false): void {
    setTimeout(() => {
      if (
        !this.gridApi ||
        !this.gridColumnApi ||
        (!force && !this.gridColumnApi.getAllColumns().some(col => col.getColDef().autoHeight))
      ) {
        return;
      }

      this.gridApi.resetRowHeights();
    }, 50);
  }

  menuItemSelected(itemEvent: MenuItemEvent, rowNode: RowNode): void {
    const { data, rowIndex } = rowNode;

    switch (itemEvent.type) {
      case MenuItemEventType.ADD:
        if (itemEvent.data === RowType.PRODUCT) {
          this.addNewProduct(rowNode);
        } else if (itemEvent.data === RowType.COMMENT) {
          this.addComment(rowNode);
        } else if (itemEvent.data === RowType.SUBTOTAL) {
          this.subtotalAdded.emit({ rowIndex });
        }

        break;
      case MenuItemEventType.REMOVE:
        this.itemRemoved.emit({ id: data.id, rowType: data.rowType });
        break;
      case MenuItemEventType.CLONE:
        this.itemCloned.emit({ id: data.id, rowType: data.rowType, rowIndex });
        break;
      case MenuItemEventType.CUSTOM:
        this.itemCustomEvent.emit({
          id: rowNode.data.id,
          rowType: rowNode.data.rowType,
          eventType: itemEvent.data
        });
        break;
      default:
    }
  }

  onCellValueChanged({ data, oldValue, newValue, colDef, columnApi, rowIndex }: CellValueChangedEvent): void {
    if (!columnApi) {
      return;
    }
    const col = columnApi.getColumn(colDef.field);
    const userColDef = col && col.getUserProvidedColDef();
    const rowData = { ...data };

    if (rowData.rowType === RowType.EMPTY) {
      rowData.rowType = RowType.PRODUCT;
    }

    if (userColDef && userColDef.autoHeight) {
      this.gridApi.resetRowHeights();
    }

    this.itemEdited.emit({
      id: rowData.id,
      rowType: rowData.rowType,
      rowIndex,
      oldValue,
      newValue,
      property: data.rowType === RowType.COMMENT ? 'text' : colDef.field,
      data: rowData
    });
  }

  private patchColumnDefs(columnDefinitions: ColDef[]): void {
    if (!columnDefinitions?.length) {
      return;
    }

    const columnDefCopy = [...columnDefinitions];
    const subtotalColumnsIndex = this.findSubtotalColumnsIndex(columnDefCopy);

    const [firstColumn] = columnDefCopy;

    // hack purely for the correct calculation of row heights (with autoHeight enabled)
    // since when new empty product or comment is inserted into the table, ag-grid makes row too short
    if (columnDefCopy.some(col => col.autoHeight)) {
      columnDefCopy.splice(0, 1, {
        ...firstColumn,
        autoHeight: true,
        valueFormatter: ({ value }) => value ?? ' '
      });
    }

    const specialColumnStartIndex = columnDefCopy.findIndex(colDef => {
      return colDef?.cellRendererParams?.isSpecialColumnStart;
    });

    if (specialColumnStartIndex !== -1) {
      const specialColumnStart = columnDefCopy[specialColumnStartIndex];

      // add support for comment and subtotal type rows
      const specialColumnStartTypes =
        (typeof specialColumnStart.type === 'string' ? [specialColumnStart.type] : specialColumnStart.type) || [];
      columnDefCopy.splice(specialColumnStartIndex, 1, {
        ...specialColumnStart,
        type: [...specialColumnStartTypes, 'special'],
        colSpan: ({ data }) => {
          switch (data.rowType) {
            case RowType.COMMENT:
              return columnDefinitions.length - 1;
            case RowType.SUBTOTAL:
              return subtotalColumnsIndex[0] - specialColumnStartIndex;
            default:
              return 1;
          }
        },
        editable: params => {
          switch (params.data.rowType) {
            case RowType.COMMENT:
              return true;
            case RowType.SUBTOTAL:
              return false;
            default: {
              const targetField = columnDefinitions.find(c => c.field === params.colDef.field);
              return typeof targetField.editable === 'function' ? targetField.editable(params) : !!targetField.editable;
            }
          }
        }
      });

      // add support for subtotal value cells
      subtotalColumnsIndex.forEach((columnNumber: number, index, array) => {
        const column = columnDefinitions[columnNumber];
        const calculatedColSpan = (array[index + 1] ?? columnDefinitions.length) - array[index];

        columnDefCopy.splice(columnNumber, 1, {
          ...column,
          editable: params => {
            if (params.data.rowType === RowType.SUBTOTAL) {
              return false;
            }

            const editable = columnDefinitions.find(c => c.field === params.colDef.field).editable;
            return typeof editable === 'function' ? editable(params) : !!editable;
          },
          colSpan: ({ data }) => {
            return data.rowType === RowType.SUBTOTAL ? calculatedColSpan : 1;
          },
          cellClass: ({ data }) => (data.rowType === RowType.SUBTOTAL ? 'vd-cell-subtotal-value' : void 0)
        });
      });
    }

    const menuCell: ColDef[] = this.tableConfig.isEnableMenu
      ? [
          {
            field: 'rowType',
            headerName: '',
            type: 'internal',
            width: 24,
            headerClass: 'vd-header-cell-menu',
            cellClass: 'vd-cell-menu',
            lockPosition: true,
            pinned: 'left',
            cellRendererParams: { type: 'menu' },
            valueGetter: this.rowMenuConfigGetter
          }
        ]
      : [];
    const dragCell: ColDef[] = this.tableConfig.isEnableDrag
      ? [
          {
            type: 'internal',
            width: 24,
            rowDrag: ({ data }) => data.rowType !== RowType.EMPTY,
            headerClass: 'vd-header-cell-drag',
            cellClass: 'vd-cell-drag'
          }
        ]
      : [];
    const selectSell: ColDef[] = this.tableConfig.isEnableSelect
      ? [
          {
            type: 'internal',
            width: 24,
            cellClass: _ => (this._gridOptions.rowSelection === 'multiple' ? 'vd-cell-checkbox' : 'vd-cell-radio'),
            headerCheckboxSelection: _ => this._gridOptions.rowSelection === 'multiple',
            checkboxSelection: true
          }
        ]
      : [];

    // add drag and select columns
    columnDefCopy.unshift(...menuCell, ...dragCell, ...selectSell);

    if (this.gridApi) {
      this.gridApi.setColumnDefs([]);
      this.colDefs = columnDefCopy;
      this.gridApi.setColumnDefs(this.colDefs);
    } else {
      this.colDefs = columnDefCopy;
    }
  }

  private findSubtotalColumnsIndex(columns: ColDef[]): number[] {
    return columns.reduce((indexes, col, index) => {
      if (col.cellRendererParams?.isSubtotalResult) {
        indexes.push(index);
      }
      return indexes;
    }, []);
  }

  private addNewProduct(initiatorRowNode: RowNode): void {
    const productRow: ProductRow = {
      id: generateId(),
      rowType: RowType.PRODUCT
    };

    const newProductIndex = initiatorRowNode.rowIndex + 1;

    this.insertRowAt(productRow, newProductIndex);
  }

  private addComment(initiatorRowNode: RowNode): void {
    const commentRow: CommentRow = {
      id: generateId(),
      rowType: RowType.COMMENT,
      text: void 0
    };

    const newCommentIndex = initiatorRowNode.rowIndex + 1;

    this.insertRowAt(commentRow, newCommentIndex);
  }

  private insertRowAt(rowToInsert: TableRow, atIndex: number): void {
    this.gridApi.updateRowData({ addIndex: atIndex, add: [rowToInsert] });

    const addedNode = this.gridApi.getRenderedNodes().find(n => n.rowIndex === atIndex);
    if (!addedNode) {
      return;
    }

    const firstEditableColumn = this.gridColumnApi.getAllDisplayedColumns().find(c => c.isCellEditable(addedNode));

    if (firstEditableColumn) {
      // move focus and editing calls to the end of the event loop so that Angular could render newly added row first
      setTimeout(() => {
        this.gridApi.startEditingCell({ rowIndex: addedNode.rowIndex, colKey: firstEditableColumn.getColId() });
      }, 150);
    }
  }

  private setSelected(rowNode: RowNode, selectedRowsIds: string[]): void {
    rowNode.setSelected(selectedRowsIds.includes(rowNode.data.id), false, true);
  }

  private forEachNode(forEachNodeCallback: (owNode: RowNode) => void): void {
    this.gridApi.forEachNode(forEachNodeCallback);
  }
}
