import { Observable } from 'rxjs';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ButtonRole } from '../../models';
import { ReleaseVersionService } from './release-version.service';

@Component({
  selector: 'vle-release-version',
  templateUrl: './release-version.component.html',
  styleUrls: ['./release-version.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReleaseVersionComponent implements OnInit {
  @Input() initialUIVersion?: string;
  @Input() initialBackendVersion?: string;
  @Input() initialIntegrationVersion?: string;
  @Input() backendVersionUrl?: string = '/backend-version.json';
  @Input() UIVersionUrl?: string = '/ui-version.json';
  @Input() integrationVersionParam?: string = 'sf-version';

  ButtonRole = ButtonRole;

  UIVersion$: Observable<string>;
  backendVersion$: Observable<string>;
  integrationVersion$: Observable<string>;

  constructor(private releaseVersionService: ReleaseVersionService) {}

  ngOnInit(): void {
    this.backendVersion$ = this.releaseVersionService.getBackendVersion(this.backendVersionUrl);
    this.UIVersion$ = this.releaseVersionService.getUIVersion(this.UIVersionUrl);
    this.integrationVersion$ = this.releaseVersionService.getIntegrationVersion(this.integrationVersionParam);
  }
}
