import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonRole } from '../../models';

const getSelectedFilename = (files: FileList): string => {
  if (files?.length === 1) {
    return files[0].name;
  } else if (files.length > 1) {
    return `${files.length} files selected`;
  }

  return 'No files selected';
};

@Component({
  selector: 'vle-file-chooser',
  templateUrl: './file-chooser.component.html',
  styleUrls: ['./file-chooser.component.scss']
})
export class FileChooserComponent implements OnInit {
  @Input() acceptType: string = '';
  @Input() buttonLabel: string;
  @Input() buttonRole?: ButtonRole = ButtonRole.Primary;
  @Input() isMultiple?: boolean;
  @Input() isInputShown?: boolean;
  @Input() isWide?: boolean;
  @Input() initialFileName?: string;
  @Input() hasError?: boolean;

  ButtonRole = ButtonRole;

  selectedFilename: string;

  @Output() private fileSelect = new EventEmitter<FileList>();

  ngOnInit(): void {
    this.selectedFilename = this.initialFileName || 'No files selected';
  }

  onSelectedFile(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.files?.length) {
      this.selectedFilename = getSelectedFilename(input.files);
      this.fileSelect.emit(input.files);
    }
  }

  get classMap(): { [key: string]: boolean } {
    return {
      'vle-file-chooser--inputable': this.isInputShown,
      'vle-file-chooser--wide': this.isWide
    };
  }
}
