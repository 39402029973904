import { Pipe, PipeTransform } from '@angular/core';
import { DropdownItem } from '../models';
import { isEqual } from 'lodash';

@Pipe({
  name: 'dropdownDisplayValue'
})
export class DropdownDisplayValuePipe implements PipeTransform {
  transform(dropdownValue: DropdownItem['value'], dropdownItems: DropdownItem[] = []): DropdownItem['displayValue'] {
    const dropdownItem = dropdownItems.find(({ value }) => isEqual(value, dropdownValue));

    return dropdownItem?.displayValue ?? dropdownItem?.value ?? null;
  }
}
