import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Filter, FilterableOp } from '../../../models';

@Component({
  selector: 'vle-input-filter',
  templateUrl: './input-filter.component.html',
  styleUrls: ['./input-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputFilterComponent implements OnInit, OnChanges, OnDestroy {
  @Input() filter: Filter;

  inputFormControl: FormControl;

  private readonly keyUpSubject = new Subject<void>();
  private readonly onDestroy = new Subject<void>();
  @Output() private readonly searchUpdated = new EventEmitter<Filter>();

  ngOnInit(): void {
    this.inputFormControl = new FormControl(this.filter?.condition?.value);
    this.activateSearch();
  }

  ngOnChanges(): void {
    this.inputFormControl = new FormControl(this.filter?.condition?.value);
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  onKeyUp(): void {
    this.keyUpSubject.next();
  }

  private activateSearch(): void {
    this.keyUpSubject.pipe(debounceTime(100), takeUntil(this.onDestroy)).subscribe(() => this.search());
  }

  private search(): void {
    const searchString = (this.inputFormControl.value || '').trim();

    const filter = {
      ...new Filter(this.filter.filterable),
      condition: {
        key: this.filter.filterable.name,
        value: searchString,
        operator: FilterableOp.LIKE
      }
    };

    this.searchUpdated.emit(filter);
  }
}
