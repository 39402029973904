import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownItem, Filter, Filterable } from '../../../models';
import * as _ from 'lodash';

@Component({
  selector: 'vle-add-filter',
  templateUrl: './add-filter.component.html',
  styleUrls: ['./add-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddFilterComponent implements OnInit {
  @Input() isRightAligned?: boolean;
  @Input() dropdownId?: string;

  dropdownItems: DropdownItem<number>[];

  @Output() private filterSelected = new EventEmitter<Filter>();
  private filterables: Filterable[] = [];

  get menuItems(): Filterable[] {
    return this.filterables;
  }

  @Input() set menuItems(value: Filterable[]) {
    this.filterables = _.uniqBy(value, 'label');

    this.initDropdownItems();
  }

  onMenuItemSelect({ value }: DropdownItem): void {
    this.filterSelected.emit(new Filter(this.filterables[Number(value)]));
  }

  ngOnInit(): void {
    this.initDropdownItems();
  }

  private initDropdownItems(): void {
    this.dropdownItems = this.filterables.map(({ label, className }, index) => ({
      value: index,
      displayValue: label,
      className
    }));
  }
}
