import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vle-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent {
  @Input() name?: string;
  @Input() isChecked?: boolean;
  @Input() isIndeterminate?: boolean;
  @Input() isDisabled?: boolean;
  @Input() isRadio?: boolean;
  @Input() labelText?: string;

  @Output() private checkedChange = new EventEmitter<Event>();

  onChange(event: Event): void {
    this.checkedChange.emit(event);
  }
}
