import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  ViewChild
} from '@angular/core';
import { DropdownItem } from '../models';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';

enum Trigger {
  CLICK = 'click',
  HOVER = 'hover'
}

@Component({
  selector: 'vl-dropdown',
  styleUrls: ['dropdown.component.scss'],
  templateUrl: 'dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VlDropdownComponent {
  @ViewChild('dropdown', { read: BsDropdownDirective, static: false }) dropdown: BsDropdownDirective;
  @ViewChild('toggleElement') toggleElement: ElementRef;
  @Input() menuItems: DropdownItem<unknown>[];
  @Input() autoClose?: boolean = true;
  @Input() insideClick?: boolean = false;
  @Input() dropup?: boolean = false;
  @Input() autoDropup?: boolean = false;
  @Input() dropdownHeight?: number = 0;
  @Input() appendToBody?: boolean = true;
  @Input() isWide?: boolean;
  @Input() isDisabled?: boolean;
  @Input() dividerAt?: number;
  @Input() trigger? = Trigger.CLICK;
  @Input() dropdownId? = '';
  @Input() isRightAligned?: boolean;

  Trigger = Trigger;

  @Output() private handleMenuItemSelect = new EventEmitter<DropdownItem>();
  @Output() private toggleDropdown = new EventEmitter<boolean>();

  constructor(private ngZone: NgZone) {}

  onMenuItemSelect(item: DropdownItem): void {
    this.handleMenuItemSelect.emit(item);
  }

  onToggleButtonClick(): void {
    if (this.dropdown.isOpen) {
      this.dropdown.hide();
    } else {
      if (this.autoDropup) {
        const bounding = this.toggleElement.nativeElement.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        const dropup = bounding.bottom + this.dropdownHeight > windowHeight;
        this.dropdown.dropup = dropup;
        this.dropdown.placement = `${dropup ? 'top' : 'bottom'} ${this.isRightAligned ? 'right' : 'left'}`;
      }
      this.dropdown.show();
    }
  }

  dropdownShownHandler(): void {
    this.ngZone.run(() => {
      setTimeout(() => {
        // https://veloce.atlassian.net/browse/CRQ-884
        // https://veloce.atlassian.net/browse/CRQ-996
        // this hack is needed to run extra change detection
        // because when component is used in runtime it doesn't always open
      }, 50);
    });
    this.toggleDropdown.emit(true);
  }

  dropdownHiddenHandler(): void {
    this.toggleDropdown.emit(false);
  }

  show(): void {
    this.onToggleButtonClick();
  }

  trackById(_: number, item: DropdownItem<any>): unknown {
    return item.id;
  }
}
