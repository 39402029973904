import { FormControl } from '@angular/forms';

export enum ErrorLevel {
  ERROR = 'ERROR',
  WARNING = 'WARNING'
}

export enum MimeType {
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
}

export const requiredValidator = (errorLevel: ErrorLevel, message?: string) => {
  return ({ value }: FormControl) => {
    if (value === void 0 || value === null || value.trim() === '') {
      return { [errorLevel]: { message } };
    }

    return null;
  };
};

export const mimeTypeValidator = (allowedTypes: string[]) => {
  return ({ value }: FormControl) => {
    const file: Partial<File> = value;

    if (file && !allowedTypes.includes(file.type)) {
      return { notAllowed: { message: `File type not allowed` } }
    }

    return null
  }
}
