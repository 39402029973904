import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vle-template-tab',
  styleUrls: ['template-tab.component.scss'],
  templateUrl: 'template-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateTabComponent {
  @Input() templateTabs: string[] = [];
  @Input() currentTemplateTab: string;

  @Output() private templateTabSelect = new EventEmitter<string>();

  onTemplateTabSelect(tab: string): void {
    this.templateTabSelect.emit(tab);
  }

  trackByValue = (_: number, value: string): string => value;
}
