import * as _ from 'lodash';
import { DragulaService } from 'ng2-dragula';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ButtonRole, DataSelectorItem, TableConfig } from '../../models';

@Component({
  selector: 'vle-table-configuration',
  templateUrl: './table-configuration.component.html',
  styleUrls: ['./table-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableConfigurationComponent implements OnInit, OnDestroy {
  @Input() title = 'TABLE CONFIGURATION';
  @Input() applyButtonLabel = 'SAVE CONFIGURATION';
  @Input() resetButtonLabel = 'RESET TO DEFAULT';
  @Input() tableConfig: TableConfig;
  @Input() isScrollbarThin = false;
  @Input() isReorderEnabled = true;

  readonly dragulaGroup = 'items' + new Date().getTime();
  transformedData: DataSelectorItem[];
  isDisabled = true;

  settingItems: TableConfig['settings'];

  ButtonRole = ButtonRole;

  @Output() private configSave = new EventEmitter<TableConfig>();

  @Output() private configReset = new EventEmitter<void>();
  @Output() private closePanel = new EventEmitter<void>();

  private destroy$ = new Subject<void>();

  @ViewChild('mirrorContainerRef', { static: true }) private mirrorContainerRef: ElementRef;

  constructor(private dragulaService: DragulaService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    const { items = [], settings = [] } = this.tableConfig;
    this.transformedData = _.chain(items)
      .sortBy('isStatic')
      .map(item => ({ ...item }))
      .value();
    this.settingItems = settings;

    if (this.isReorderEnabled) {
      this.dragulaService.createGroup(this.dragulaGroup, {
        direction: 'vertical',
        mirrorContainer: this.mirrorContainerRef.nativeElement.children[0],
        moves: (el: any) => !el.classList.contains('table-configuration-body-list__item--static')
      });

      this.dragulaService
        .dropModel()
        .pipe(takeUntil(this.destroy$))
        .subscribe(({ targetModel: sortedDataItems }) => {
          this.transformedData = sortedDataItems;
          this.isDisabled = false;
          this.cdr.markForCheck();
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.isReorderEnabled) {
      this.dragulaService.destroy(this.dragulaGroup);
    }
  }

  toggle(item: DataSelectorItem, isChecked: boolean): void {
    this.transformedData = this.transformedData.map(transformItem => {
      return item.id === transformItem.id ? { ...transformItem, isChecked } : transformItem;
    });
    this.isDisabled = false;
  }

  toggleSetting(item: DataSelectorItem, isChecked: boolean): void {
    this.settingItems = this.settingItems.map(settingItem => {
      return settingItem.id === item.id ? { ...settingItem, isChecked } : settingItem;
    });
    this.isDisabled = false;
  }

  close(): void {
    this.closePanel.emit();
  }

  trackById = (_: number, { id }: DataSelectorItem): string => id;

  resetToDefault(): void {
    this.configReset.emit();
  }

  saveConfiguration(): void {
    this.configSave.emit({ items: this.transformedData, settings: this.settingItems });
  }
}
