import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Component } from '@angular/core';

interface ContentObject {
  content: string;
  clickHandler: (params: ICellRendererParams) => void;
}

@Component({
  selector: 'vle-content-cell-renderer',
  templateUrl: 'content-cell-renderer.component.html'
})
export class ContentCellRendererComponent implements ICellRendererAngularComp {
  content: string;
  contentObject: ContentObject;
  params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.setContent(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.setContent(params);

    return true;
  }

  getContent(params: ICellRendererParams): string | ContentObject {
    const { cellRendererParams } = params.colDef;

    return cellRendererParams?.cellContentGetter(params);
  }

  onClick(): void {
    this.contentObject?.clickHandler(this.params);
  }

  private setContent(params: ICellRendererParams): void {
    const content = this.getContent(params);

    this.params = params;

    if ((<ContentObject>content)?.clickHandler) {
      this.contentObject = content as ContentObject;
    } else {
      this.content = content as string;
    }
  }
}
