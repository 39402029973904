import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Toaster, ToastPosition, ToastSettings } from '../../models';

enum ToastType {
  Error = 1,
  Warning,
  Success,
  Info
}

const toastTypeClassMap = {
  [ToastType.Error]: 'vle-toast--error',
  [ToastType.Warning]: 'vle-toast--warning',
  [ToastType.Success]: 'vle-toast--success',
  [ToastType.Info]: 'vle-toast--info'
};

@Component({
  selector: 'vle-toast',
  styleUrls: ['toast.component.scss'],
  templateUrl: 'toast.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent implements OnInit {
  @Input() isCompact?: boolean = false;
  @Input() isCloseOnClick?: boolean = false;
  @Input() position?: ToastPosition = ToastPosition.topLeft;
  @Input() timeout?: number;

  @Output() toastInitialized = new EventEmitter<unknown>();

  constructor(@Inject('Toaster') private toaster: Toaster) {}

  ngOnInit(): void {
    this.toaster.settings({
      animateInside: false,
      closeOnClick: this.isCloseOnClick,
      drag: false,
      progressBar: false,
      target: '#toasterAnchor',
      timeout: isNaN(this.timeout) ? 3000 : this.timeout,
      position: this.position
    });

    this.toastInitialized.emit({
      error: this.toastError.bind(this),
      warning: this.toastWarning.bind(this),
      success: this.toastSuccess.bind(this),
      info: this.toastInfo.bind(this)
    });
  }

  toastError(message: string, settings?: ToastSettings): void {
    this.toast(message, toastTypeClassMap[ToastType.Error], settings || {});
  }

  toastWarning(message: string, settings?: ToastSettings): void {
    this.toast(message, toastTypeClassMap[ToastType.Warning], settings || {});
  }

  toastSuccess(message: string, settings?: ToastSettings): void {
    this.toast(message, toastTypeClassMap[ToastType.Success], settings || {});
  }

  toastInfo(message: string, settings?: ToastSettings): void {
    this.toast(message, toastTypeClassMap[ToastType.Info], settings || {});
  }

  private toast(message: string, className: string, settings: ToastSettings): void {
    this.toaster.show({ ...settings, message, class: className });
  }

  get toastClassMap(): { [key: string]: boolean } {
    return {
      'iziToast--compact': this.isCompact,
      [`iziToast-alignment--${this.position}`]: !!this.position
    };
  }
}
