import { Injector, Pipe, PipeTransform, Type } from '@angular/core';

@Pipe({
  name: 'dynamicPipe'
})
export class DynamicPipe implements PipeTransform {
  constructor(private injector: Injector) {}

  transform(value: string, pipeToken?: Pipe, pipeArgs: unknown[] = []): string {
    if (!pipeToken) {
      return value;
    }

    const pipe = this.injector.get<PipeTransform>(pipeToken as Type<Pipe>);

    return pipe.transform(value, ...pipeArgs);
  }
}
