import { Injectable } from '@angular/core';

@Injectable()
export class FileDownloadService {
  private readonly defaultFileName = 'veloce-export.txt';
  private readonly regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

  processDownload(response: any, fileName?: string): void {
    const name = fileName || this.getFileName(response.headers.get('Content-Disposition')) || this.defaultFileName;

    this.downloadFile(name, response.body || response);
  }

  downloadFile(fileName: string, blob: Blob): void {
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', fileName);
    tempLink.setAttribute('target', '_blank');

    document.body.appendChild(tempLink);
    tempLink.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    window.URL.revokeObjectURL(blobURL);
    tempLink.remove();
  }

  private getFileName(contentDisposition: string): string {
    if (!contentDisposition?.includes('attachment')) {
      return;
    }

    const matches = this.regex.exec(contentDisposition);
    if (matches?.[1]) {
      return matches[1].replace(/['"]/g, '');
    }
  }
}
