import { Injectable } from '@angular/core';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  GooglePlaceDetailsResponse,
  GooglePlaceSearchRequest,
  GooglePlaceSearchResponse,
  GooglePlacesServiceSearchRequest,
  MapBoxPlacesSearchResponse,
  MapBoxSearchRequest
} from './address-search.model';

/**
 * https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service
 */
interface GoogleAutocompleteService {
  getPlacePredictions: (request: GooglePlaceSearchRequest) => Promise<GooglePlaceSearchResponse>;
}

/**
 * https://developers.google.com/maps/documentation/javascript/reference/places-service#PlacesService
 */
interface GooglePlacesService {
  getDetails(request: GooglePlacesServiceSearchRequest, callback: (data: GooglePlaceDetailsResponse) => void): void;
}

@Injectable()
export class AddressSearchService {
  private googleAPI$;

  constructor(private http: HttpClient) {}

  initGoogleAPI(apiKey: string): Observable<unknown> {
    const googleRef = (window as any).google;
    if (typeof googleRef === 'object' && typeof googleRef.maps === 'object' && !this.googleAPI$) {
      // case if api already loaded but service/module was re-initialized (like in storybook)
      return of(undefined);
    } else if (!this.googleAPI$) {
      const apiVersion = 'beta'; // support for promises
      const googleAPI = `https://maps.googleapis.com/maps/api/js?v=${apiVersion}&key=${apiKey}&libraries=places`;
      this.googleAPI$ = this.http.jsonp(googleAPI, 'callback').pipe(shareReplay(1));
    }
    return this.googleAPI$;
  }

  googlePlacePredictions(request: GooglePlaceSearchRequest, apiKey: string): Observable<GooglePlaceSearchResponse> {
    return this.initGoogleAPI(apiKey).pipe(
      map(() => {
        return new (window as any).google.maps.places.AutocompleteService();
      }),
      shareReplay(1),
      switchMap((service: GoogleAutocompleteService) => {
        return service?.getPlacePredictions(request);
      })
    );
  }

  googlePlaceService(element: any, apiKey: string): Observable<GooglePlacesService> {
    return this.initGoogleAPI(apiKey).pipe(
      map(() => {
        return new (window as any).google.maps.places.PlacesService(element);
      }),
      shareReplay(1)
    );
  }

  mapBoxPlacePredictions(request: MapBoxSearchRequest, apiKey: string): Observable<MapBoxPlacesSearchResponse> {
    const url = new URL(`https://api.mapbox.com/geocoding/v5/mapbox.places/${request.input}.json`);
    url.searchParams.append('access_token', apiKey);
    url.searchParams.append('limit', request.limit);
    if (request.country) {
      url.searchParams.append('country', request.country);
    }
    if (request.types) {
      url.searchParams.append('types', request.types);
    }
    return this.http.get<MapBoxPlacesSearchResponse>(url.href);
  }
}
