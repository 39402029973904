import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonRole } from '../../../models';

@Component({
  selector: 'vle-template-expandable-area',
  styleUrls: ['template-expandable-area.component.scss'],
  templateUrl: 'template-expandable-area.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateExpandableAreaComponent {
  @Input() isExpanded: boolean;

  ButtonRole = ButtonRole;

  @Output() private toggleExpand = new EventEmitter<void>();

  onToggleExpand(): void {
    this.toggleExpand.emit();
  }
}
