import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Component } from '@angular/core';
import { DropdownActions } from '../../../../models';

@Component({
  selector: 'vle-dropdown-cell-renderer',
  templateUrl: 'dropdown-cell-renderer.component.html',
  styleUrls: ['dropdown-cell-renderer.component.scss']
})
export class DropdownCellRendererComponent implements ICellRendererAngularComp {
  data: DropdownActions;

  agInit(params: ICellRendererParams): void {
    this.setContent(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.setContent(params);
    return true;
  }

  onMenuItemClick(item: any): void {
    this.data.clickHandler({
      id: this.data.id,
      action: item.action
    });
  }

  private setContent({ value }: ICellRendererParams): void {
    this.data = value || {};
  }

  trackByLabel(_: number, { label }): string {
    return label;
  }
}
