import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonRole, DocumentTemplate } from '../../../models';

@Component({
  selector: 'vle-template-list-admin',
  styleUrls: ['template-list-admin.component.scss'],
  templateUrl: 'template-list-admin.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateListAdminComponent {
  @Input() templates: DocumentTemplate[] = [];
  @Input() isModalCloseButtonHidden: boolean;

  ButtonRole = ButtonRole;

  @Output() private viewCancel = new EventEmitter<void>();
  @Output() private templateSelect = new EventEmitter<string>();

  onViewCancel(): void {
    this.viewCancel.emit();
  }

  onTemplateSelect(templateId: string): void {
    this.templateSelect.emit(templateId);
  }

  trackById = (_: number, { id }: DocumentTemplate): string => id;
}
