import {
  CellClassParams,
  CellEditingStartedEvent,
  CellEditingStoppedEvent,
  CellFocusedEvent,
  CellValueChangedEvent,
  ColDef,
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellEditorParams,
  ICellRendererParams,
  IsColumnFuncParams,
  RowDragEvent,
  RowNode,
  ValueGetterParams
} from 'ag-grid-community';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  CommentRow,
  ErrorLevel,
  MenuItemEvent,
  MenuItemEventType,
  ProductRow,
  RowMenuConfig,
  RowType,
  TableRow
} from '../../models';
import { TextCellEditorComponent } from './editor/text-cell-editor/text-cell-editor.component';
import { CheckboxCellRendererComponent } from './renderer/checkbox-cell-renderer/checkbox-cell-renderer.component';
import { CommentCellRendererComponent } from './renderer/comment-cell-renderer/comment-cell-renderer.component';
import { ContentCellRendererComponent } from './renderer/content-cell-renderer/content-cell-renderer.component';
import { DropdownCellRendererComponent } from './renderer/dropdown-cell-renderer/dropdown-cell-renderer.component';
import { GroupRendererComponent } from './renderer/group-renderer/group-renderer.component';
import { MenuCellRendererComponent } from './renderer/menu-cell-renderer/menu-cell-renderer.component';
import { SelectionCheckboxCellRendererComponent } from './renderer/selection-checkbox-cell-renderer/selection-checkbox-cell-renderer.component';
import { SubtotalCellRendererComponent } from './renderer/subtotal-cell-renderer/subtotal-cell-renderer.component';
import { TooltipCellRendererComponent } from './renderer/tooltip-cell-renderer/tooltip-cell-renderer.component';

interface TableConfig {
  isEnableMenu?: boolean;
  isEnableDrag?: boolean;
  isEnableSelect?: boolean;
  isEnableMultiRowDragging?: boolean;
  isEnableGrouping?: boolean;
  isEnableHorizontalScroll?: boolean;
  isGroupColumnUnpinned?: boolean;
  hideSelectAll?: boolean;

  isSelectionDisabled?(params: ICellRendererParams | RowNode): boolean;
  isInternallySelected?(params: ICellRendererParams | RowNode): boolean;

  groupColumnWidth?: number;
}

interface RowToggleExpandEvent {
  isExpanded: boolean;
  data: unknown;
}

const generateId = () => Date.now().toString();

const isCellEditable = (params: CellClassParams | ICellRendererParams | ICellEditorParams) => {
  const { editable } = params.colDef;
  return typeof editable === 'function' ? editable(params as IsColumnFuncParams) : !!editable;
};

const setSelected = (rowNode: RowNode, selectedRowsIds: string[]): void => {
  rowNode.setSelected(selectedRowsIds.includes(rowNode.data.id), false, true);
};

const findSubtotalColumnsIndex = (columns: ColDef[]): number[] => {
  return columns.reduce((indexes, col, index) => {
    if (col.cellRendererParams?.isSubtotalResult) {
      indexes.push(index);
    }
    return indexes;
  }, []);
};

@Component({
  selector: 'vle-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent implements OnInit, OnChanges {
  @Input() gridOptions: Partial<GridOptions>;
  @Input() rowData: TableRow[];
  @Input() tableConfig: TableConfig = {};
  @Input() rowMenuConfigGetter: (params: ValueGetterParams) => RowMenuConfig;
  @Input() rowClassRules: GridOptions['rowClassRules'] = {};

  get columnDefs(): ColDef[] {
    return this._columnDefs;
  }

  @Input() set columnDefs(value: ColDef[]) {
    this._columnDefs = value;

    setTimeout(() => {
      this.gridColumnApi?.resetColumnState();
    });
  }

  _columnDefs: ColDef[] = [];

  colDefs: ColDef[] = [];
  indexedRowData: TableRow[] = [];

  context: any = { componentParent: this };

  _gridOptions: GridOptions;

  openGroupsIds: string[] = [];

  @Output() private tableReady = new EventEmitter<TableComponent>();
  @Output() private selectionChanged = new EventEmitter<any[]>();
  @Output() private itemDragged = new EventEmitter<string[]>();
  @Output() private itemEdited = new EventEmitter<any>();
  @Output() private itemCustomEvent = new EventEmitter<any>();
  @Output() private itemRemoved = new EventEmitter<any>();
  @Output() private itemCloned = new EventEmitter<any>();
  @Output() private subtotalAdded = new EventEmitter<{ rowIndex: number }>();
  @Output() private rowExpandToggle = new EventEmitter<RowToggleExpandEvent>();
  @Output() private cellFocused = new EventEmitter<CellFocusedEvent>();
  @Output() private cellEditingStarted = new EventEmitter<CellEditingStartedEvent>();
  @Output() private cellEditingStopped = new EventEmitter<CellEditingStoppedEvent>();

  private gridApi: GridApi;
  private gridColumnApi: ColumnApi;

  ngOnInit(): void {
    this._gridOptions = {
      columnTypes: {
        currency: {
          valueFormatter: ({ value, colDef }) => {
            try {
              const digitsInfo = colDef?.cellRendererParams?.digitsInfo || '1.2-2';
              const currencyCode = colDef?.cellRendererParams?.currencyCode || 'USD';
              return new CurrencyPipe('en-US').transform(value, currencyCode, 'symbol', digitsInfo);
            } catch (e) {
              return void 0;
            }
          }
        },
        float: {
          valueFormatter: ({ value, colDef }) => {
            try {
              const digitsInfo = colDef?.cellRendererParams?.digitsInfo || '1.2-2';
              return new DecimalPipe('en-US').transform(value, digitsInfo);
            } catch (e) {
              return void 0;
            }
          }
        },
        internal: {
          editable: false,
          suppressNavigable: true,
          suppressSizeToFit: true
        },
        special: {
          cellRendererSelector: ({ data }) => {
            switch (data.rowType) {
              case RowType.COMMENT:
                return { component: 'commentCellRenderer' };
              case RowType.SUBTOTAL:
                return { component: 'subtotalCellRenderer' };
              default:
                return null;
            }
          },
          valueSetter: ({ data, colDef, newValue }) => {
            if (data.rowType === RowType.COMMENT) {
              data.text = newValue;
            } else {
              data[colDef.field] = newValue;
            }

            return true;
          },
          cellClass: ({ data }) => {
            switch (data.rowType) {
              case RowType.COMMENT:
                return 'vle-cell-comment';
              case RowType.SUBTOTAL:
                return 'vle-cell-subtotal';
              default:
                return void 0;
            }
          }
        }
      },
      defaultColDef: {
        resizable: false,
        suppressMovable: true,
        suppressNavigable: ({ data }) => data.rowType === RowType.SUBTOTAL,
        rowDragText: () => '',
        cellStyle: ({ colDef }) => {
          if (colDef && colDef.autoHeight) {
            return { 'white-space': 'pre-wrap' };
          }

          return void 0;
        },
        cellRendererSelector: params => {
          switch (params.colDef.cellRendererParams?.type) {
            case 'menu':
              return { component: 'menuCellRenderer' };
            case 'group':
              return { component: 'groupCellRenderer' };
            case 'custom':
              return { component: 'contentCellRenderer' };
            case 'dropdown':
              return { component: 'dropdownCellRenderer' };
            case 'checkbox':
              return { component: 'checkboxCellRenderer' };
            case 'selectedCheckbox':
              return { component: 'selectedCheckboxCellRenderer' };
            case 'tooltip':
              return { component: 'tooltipCellRenderer' };
            default:
              return null;
          }
        },
        cellEditorSelector: params => {
          const { colDef, data } = params;
          let { cellEditorParams } = colDef;
          const parameters: any = {};

          cellEditorParams = typeof cellEditorParams === 'function' ? cellEditorParams(params) : cellEditorParams;

          if (data.rowType !== RowType.PRODUCT) {
            parameters.skipValidation = true;
          }

          return {
            component:
              cellEditorParams && cellEditorParams.type === 'select' ? 'agSelectCellEditor' : 'agTextCellEditor',
            params: parameters
          };
        },
        cellClassRules: {
          'vle-cell-not-navigable': (params: CellClassParams) => {
            const { suppressNavigable } = params.colDef;

            if (typeof suppressNavigable === 'function') {
              return suppressNavigable(params as any);
            } else {
              return !!suppressNavigable;
            }
          },
          'vle-cell--error': ({ data, colDef }: CellClassParams) => {
            const validation = data.validation && data.validation[colDef.field];
            const errors = validation && validation[ErrorLevel.ERROR];

            return errors && errors.length;
          },
          'vle-cell--warning': ({ data, colDef }: CellClassParams) => {
            const validation = data.validation && data.validation[colDef.field];
            const errors = validation && validation[ErrorLevel.ERROR];
            const warnings = validation && validation[ErrorLevel.WARNING];

            return (!errors || !errors.length) && warnings && warnings.length;
          },
          'vle-cell-checkbox': ({ colDef }: CellClassParams) => {
            return colDef.cellRendererParams?.type === 'checkbox';
          },
          'vle-cell-tooltip': ({ colDef }: CellClassParams) => {
            return colDef.cellRendererParams?.type === 'tooltip';
          },
          'vle-cell-dropdown': (params: CellClassParams) => {
            const { colDef } = params;
            const cellEditorParams =
              typeof colDef.cellEditorParams === 'function' ? colDef.cellEditorParams(params) : colDef.cellEditorParams;
            return cellEditorParams?.type === 'dropdown' && isCellEditable(params);
          },
          'vle-cell-datepicker': params => {
            const { colDef } = params;
            const cellEditorParams =
              typeof colDef.cellEditorParams === 'function' ? colDef.cellEditorParams(params) : colDef.cellEditorParams;
            return cellEditorParams?.type === 'datepicker' && isCellEditable(params);
          },
          'vle-cell-disabled': (params: CellClassParams) => {
            const { colDef } = params;
            const cellEditorParams =
              typeof colDef.cellEditorParams === 'function' ? colDef.cellEditorParams(params) : colDef.cellEditorParams;

            return typeof cellEditorParams?.isDisabled === 'function'
              ? cellEditorParams.isDisabled(params)
              : cellEditorParams?.isDisabled;
          }
        }
      },
      immutableData: true,
      frameworkComponents: {
        agTextCellEditor: TextCellEditorComponent,
        checkboxCellRenderer: CheckboxCellRendererComponent,
        commentCellRenderer: CommentCellRendererComponent,
        contentCellRenderer: ContentCellRendererComponent,
        dropdownCellRenderer: DropdownCellRendererComponent,
        menuCellRenderer: MenuCellRendererComponent,
        selectedCheckboxCellRenderer: SelectionCheckboxCellRendererComponent,
        subtotalCellRenderer: SubtotalCellRendererComponent,
        tooltipCellRenderer: TooltipCellRendererComponent,
        groupCellRenderer: GroupRendererComponent
      },
      rowBuffer: 0,
      rowClassRules: {
        ...this.rowClassRules,
        'vle-table-row--subtotal': ({ data }) => data.rowType === RowType.SUBTOTAL,
        'vle-table-row--empty': ({ data }) => data.rowType === RowType.EMPTY
      },
      rowSelection: 'multiple',
      enableMultiRowDragging: this.tableConfig.isEnableDrag && this.tableConfig.isEnableMultiRowDragging,
      suppressChangeDetection: true,
      suppressRowClickSelection: true,
      onCellValueChanged: this.onCellValueChanged.bind(this),
      onGridReady: this.onGridReady.bind(this),
      rowDragManaged: true,
      animateRows: true,
      onRowDragEnd: this.onRowDragEnd.bind(this),
      onRowDragLeave: this.onRowDragEnd.bind(this),
      onSelectionChanged: this.onSelectionChanged.bind(this),
      onCellFocused: (event: CellFocusedEvent): void => {
        this.cellFocused.emit(event);
      },
      onCellEditingStarted: (event: CellEditingStartedEvent): void => {
        this.cellEditingStarted.emit(event);
      },
      onCellEditingStopped: (event: CellEditingStoppedEvent): void => {
        this.cellEditingStopped.emit(event);
      },
      getRowNodeId: ({ id }: TableRow) => id,
      rowHeight: 34,
      singleClickEdit: true,
      isRowSelectable: (params): boolean =>
        params.data &&
        params.data.rowType !== RowType.EMPTY &&
        !(typeof this.tableConfig?.isSelectionDisabled === 'function' && this.tableConfig.isSelectionDisabled(params)),
      ...this.gridOptions
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { columnDefs, rowData: initialRowData } = changes;

    if (columnDefs && columnDefs.currentValue !== columnDefs.previousValue) {
      this.patchColumnDefs(columnDefs.currentValue);

      // when columns get hidden or shown row heights can change due to changing column widths
      if (!columnDefs.firstChange) {
        if (this.gridApi) {
          this.gridApi.stopEditing(true);
        }

        this.recalculateRowHeights(true);
      }
    }

    if (initialRowData) {
      const rowData = this.reopenGroups(initialRowData?.currentValue);
      this.indexedRowData = rowData?.length
        ? rowData.map((row: TableRow, index: number) => ({ ...row, index }))
        : [{ rowType: RowType.EMPTY }];

      const selectedRowsIds = this.indexedRowData.filter(({ isSelected }) => isSelected).map(({ id }) => id);

      if (this.gridApi) {
        // due to singleClickEdit: true option need to stop editing for cases when entering value in one cell
        // populates value in the cell on the right but user automatically enters editing mode
        // in the cell on the right by hitting TAB key
        this.gridApi.stopEditing(true);
        this.rowData = [...this.indexedRowData];
        this.gridApi.setRowData(this.rowData);

        const specialRowNodes = [];

        this.forEachNode(rowNode => {
          setSelected(rowNode, selectedRowsIds);

          if (rowNode.data?.rowType !== RowType.PRODUCT) {
            specialRowNodes.push(rowNode);
          }
        });

        if (specialRowNodes.length) {
          this.gridApi.redrawRows({ rowNodes: specialRowNodes });
        }
      }
    }
  }

  onGridReady(event: GridReadyEvent): void {
    const rowData = this.rowData || [];
    const selectedRowsIds = rowData.filter(({ isSelected }) => isSelected).map(({ id }) => id);

    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;

    this.forEachNode(rowNode => {
      setSelected(rowNode, selectedRowsIds);
    });

    this.recalculateRowHeights();

    this.tableReady.emit(this);
  }

  onSelectionChanged(): void {
    const selectedRows = this.gridApi.getSelectedRows();

    this.selectionChanged.emit(selectedRows);
    this.gridApi.redrawRows();
  }

  onRowDragEnd({ api, node, overIndex }: RowDragEvent): void {
    if (node.data.index !== overIndex) {
      const orderedIds: string[] = [];

      api.forEachNode(({ id }) => {
        orderedIds.push(id);
      });

      this.itemDragged.emit(orderedIds);
    }
  }

  recalculateRowHeights(force: boolean = false): void {
    setTimeout(() => {
      if (
        !this.gridApi ||
        !this.gridColumnApi ||
        (!force && !this.gridColumnApi.getAllColumns().some(col => col.getColDef().autoHeight))
      ) {
        return;
      }

      this.gridApi.resetRowHeights();
    }, 50);
  }

  menuItemSelected(itemEvent: MenuItemEvent, rowNode: RowNode): void {
    const { data, rowIndex } = rowNode;

    switch (itemEvent.type) {
      case MenuItemEventType.ADD:
        if (itemEvent.data === RowType.PRODUCT) {
          this.addNewProduct(rowNode);
        } else if (itemEvent.data === RowType.COMMENT) {
          this.addComment(rowNode);
        } else if (itemEvent.data === RowType.SUBTOTAL) {
          this.subtotalAdded.emit({ rowIndex });
        }

        break;
      case MenuItemEventType.REMOVE:
        this.itemRemoved.emit({ id: data.id, rowType: data.rowType });
        break;
      case MenuItemEventType.CLONE:
        this.itemCloned.emit({ id: data.id, rowType: data.rowType, rowIndex });
        break;
      case MenuItemEventType.CUSTOM:
        this.itemCustomEvent.emit({
          id: rowNode.data.id,
          rowType: rowNode.data.rowType,
          eventType: itemEvent.data
        });
        break;
      default:
    }
  }

  onCellValueChanged({ data, oldValue, newValue, colDef, columnApi, rowIndex }: CellValueChangedEvent): void {
    if (!columnApi) {
      return;
    }
    const col = columnApi.getColumn(colDef.field);
    const userColDef = col && col.getUserProvidedColDef();
    const rowData = { ...data };

    if (rowData.rowType === RowType.EMPTY) {
      rowData.rowType = RowType.PRODUCT;
    }

    if (userColDef && userColDef.autoHeight) {
      this.gridApi.resetRowHeights();
    }

    this.itemEdited.emit({
      id: rowData.id,
      rowType: rowData.rowType,
      rowIndex,
      oldValue,
      newValue,
      property: data.rowType === RowType.COMMENT ? 'text' : colDef.field,
      data: rowData
    });
  }

  onCellFocused(event: CellFocusedEvent): void {
    this.cellFocused.emit(event);
  }

  onCellEditingStarted(event: CellEditingStartedEvent): void {
    this.cellEditingStarted.emit(event);
  }

  onCellEditingStopped(event: CellEditingStoppedEvent): void {
    this.cellEditingStopped.emit(event);
  }

  addChildRow(node: RowNode, addToList = true): void {
    if (addToList) {
      this.openGroupsIds.push(node.data.id);
    }
    const rowIndex = this.rowData.findIndex(data => data.id === node.data.id);
    this.rowData = [...this.rowData.slice(0, rowIndex + 1), ...node.data.children, ...this.rowData.slice(rowIndex + 1)];
    this.gridApi.setRowData(this.rowData);
  }

  removeChildRow(node: RowNode): void {
    const getChildrenLength = (el, amount = 0) => {
      let length = amount;
      if (el.children && this.openGroupsIds.includes(el.id)) {
        for (const child of el.children) {
          length += getChildrenLength(child);
        }
        this.openGroupsIds = this.openGroupsIds.filter(id => id !== el.id);
      }
      return length + 1;
    };
    const rowIndex = this.rowData.findIndex(data => data.id === node.data.id);

    this.rowData = [
      ...this.rowData.slice(0, rowIndex + 1),
      ...this.rowData.slice(rowIndex + getChildrenLength(node.data))
    ];

    this.gridApi.setRowData(this.rowData);
  }

  onRowExpandToggle(event: RowToggleExpandEvent): void {
    this.rowExpandToggle.emit(event);
  }

  private patchColumnDefs(columnDefinitions: ColDef[]): void {
    if (!columnDefinitions?.length) {
      return;
    }

    const columnDefCopy = [...columnDefinitions];
    const subtotalColumnsIndex = findSubtotalColumnsIndex(columnDefCopy);

    const [firstColumn] = columnDefCopy;

    // hack purely for the correct calculation of row heights (with autoHeight enabled)
    // since when new empty product or comment is inserted into the table, ag-grid makes row too short
    if (columnDefCopy.some(col => col.autoHeight)) {
      columnDefCopy.splice(0, 1, {
        ...firstColumn,
        autoHeight: true,
        valueFormatter: ({ value }) => value ?? ' '
      });
    }

    const specialColumnStartIndex = columnDefCopy.findIndex(colDef => {
      return colDef?.cellRendererParams?.isSpecialColumnStart;
    });

    if (specialColumnStartIndex !== -1) {
      const specialColumnStart = columnDefCopy[specialColumnStartIndex];

      // add support for comment and subtotal type rows
      const specialColumnStartTypes =
        (typeof specialColumnStart.type === 'string' ? [specialColumnStart.type] : specialColumnStart.type) || [];
      columnDefCopy.splice(specialColumnStartIndex, 1, {
        ...specialColumnStart,
        type: [...specialColumnStartTypes, 'special'],
        colSpan: ({ data }) => {
          switch (data.rowType) {
            case RowType.COMMENT:
              return columnDefinitions.length - specialColumnStartIndex;
            case RowType.SUBTOTAL:
              return subtotalColumnsIndex[0] - specialColumnStartIndex;
            default:
              return 1;
          }
        },
        editable: params => {
          switch (params.data.rowType) {
            case RowType.COMMENT:
              return true;
            case RowType.SUBTOTAL:
              return false;
            default:
              const targetField = columnDefinitions.find(c => c.field === params.colDef.field);
              return typeof targetField.editable === 'function' ? targetField.editable(params) : !!targetField.editable;
          }
        }
      });

      // add support for subtotal value cells
      subtotalColumnsIndex.forEach((columnNumber: number, index, array) => {
        const column = columnDefinitions[columnNumber];
        const calculatedColSpan = (array[index + 1] ?? columnDefinitions.length) - array[index];

        columnDefCopy.splice(columnNumber, 1, {
          ...column,
          editable: params => {
            if (params.data.rowType === RowType.SUBTOTAL) {
              return false;
            }

            const editable = columnDefinitions.find(c => c.field === params.colDef.field).editable;
            return typeof editable === 'function' ? editable(params) : !!editable;
          },
          colSpan: ({ data }) => {
            return data.rowType === RowType.SUBTOTAL ? calculatedColSpan : 1;
          },
          cellClass: ({ data }) => (data.rowType === RowType.SUBTOTAL ? 'vle-cell-subtotal-value' : void 0)
        });
      });
    }

    const menuCell: ColDef[] = this.tableConfig.isEnableMenu
      ? [
          {
            field: 'rowType',
            headerName: '',
            type: 'internal',
            width: 24,
            headerClass: 'vle-header-cell-menu',
            cellClass: 'vle-cell-menu',
            lockPosition: true,
            pinned: 'left',
            cellRendererParams: { type: 'menu' },
            valueGetter: this.rowMenuConfigGetter
          }
        ]
      : [];
    const groupCell: ColDef[] = this.tableConfig.isEnableGrouping
      ? [
          {
            type: 'internal',
            width: this.tableConfig.groupColumnWidth || 24,
            pinned: this.tableConfig.isGroupColumnUnpinned ? null : 'left',
            cellClass: 'vle-cell-group',
            cellRendererParams: { type: 'group' }
          }
        ]
      : [];
    const dragCell: ColDef[] = this.tableConfig.isEnableDrag
      ? [
          {
            type: 'internal',
            width: 24,
            pinned: 'left',
            rowDrag: ({ data }) => data.rowType !== RowType.EMPTY && !data.parentId,
            headerClass: 'vle-header-cell-drag',
            cellClass: 'vle-cell-drag'
          }
        ]
      : [];
    const selectSell: ColDef[] = this.tableConfig.isEnableSelect
      ? [
          {
            type: 'internal',
            width: 26,
            cellClass: _ => (this._gridOptions.rowSelection === 'multiple' ? 'vle-cell-checkbox' : 'vle-cell-radio'),
            headerCheckboxSelection: _ =>
              this._gridOptions.rowSelection === 'multiple' && !this.tableConfig.hideSelectAll,
            checkboxSelection: ({ data }) => !data.parentId && !this.tableConfig.isSelectionDisabled,
            cellRendererParams: { type: 'selectedCheckbox' },
            cellEditorParams: { hideSelectAll: this.tableConfig.hideSelectAll }
          }
        ]
      : [];

    // add drag and select columns
    columnDefCopy.unshift(...menuCell, ...dragCell, ...groupCell, ...selectSell);

    this.colDefs = columnDefCopy;
  }

  private addNewProduct(initiatorRowNode: RowNode): void {
    const productRow: ProductRow = {
      id: generateId(),
      rowType: RowType.PRODUCT
    };

    const newProductIndex = initiatorRowNode.rowIndex + 1;

    this.insertRowAt(productRow, newProductIndex);
  }

  private addComment(initiatorRowNode: RowNode): void {
    const commentRow: CommentRow = {
      id: generateId(),
      rowType: RowType.COMMENT,
      text: void 0
    };

    const newCommentIndex = initiatorRowNode.rowIndex + 1;

    this.insertRowAt(commentRow, newCommentIndex);
  }

  private insertRowAt(rowToInsert: TableRow, atIndex: number): void {
    this.gridApi.updateRowData({ addIndex: atIndex, add: [rowToInsert] });

    const addedNode = this.gridApi.getRenderedNodes().find(n => n.rowIndex === atIndex);
    if (!addedNode) {
      return;
    }

    const firstEditableColumn = this.gridColumnApi.getAllDisplayedColumns().find(c => c.isCellEditable(addedNode));

    if (firstEditableColumn) {
      // move focus and editing calls to the end of the event loop so that Angular could render newly added row first
      setTimeout(() => {
        this.gridApi.startEditingCell({ rowIndex: addedNode.rowIndex, colKey: firstEditableColumn.getColId() });
      }, 150);
    }
  }

  private forEachNode(forEachNodeCallback: (owNode: RowNode) => void): void {
    this.gridApi.forEachNode(forEachNodeCallback);
  }

  private reopenGroups(rowData: TableRow[]): any[] {
    if (!this.openGroupsIds.length || !rowData) return rowData;
    let result = [...rowData];
    const reopen = ids => {
      ids.forEach(id => {
        let rowIndex = result.findIndex(row => row.id === id);
        if (rowIndex >= 0) {
          result = [
            ...result.slice(0, rowIndex + 1),
            ...(result[rowIndex] as any).children,
            ...result.slice(rowIndex + 1)
          ];
        }
      });
    };
    reopen(this.openGroupsIds);
    return result;
  }
}
