import { Injectable } from '@angular/core';

const OUTPUT_FILE_MIME_TYPE = 'application/octet-stream';
const CSV_DELIMITER = ',';
const NEW_LINE_CHARACTER = '\n';

@Injectable({
  providedIn: 'root'
})
export class ConverterService {
  prepareCSV(rows: string[][], delimiter: string = CSV_DELIMITER): Blob {
    const dataStringCSV = rows
      .map(row => row.map(cell => this.escapeValueForCSV(cell)).join(delimiter))
      .join(NEW_LINE_CHARACTER);

    return new Blob([dataStringCSV], { type: OUTPUT_FILE_MIME_TYPE });
  }

  escapeValueForCSV = value => {
    return `"${value?.toString().replace(/"/g, '""') ?? ''}"`;
  };
}
