import { Observable, Subject, throwError } from 'rxjs';
import { take } from 'rxjs/operators';
import { read, utils, WorkBook, WorkSheet, write } from 'xlsx';
import * as XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';
import { Injectable } from '@angular/core';

/* DOCS: https://docs.sheetjs.com/ */

export interface HeaderMap {
  [key: string]: string;
}

export interface ParserOptions {
  blankrows?: boolean;
  defval?: undefined | null | string;
  headerMap?: HeaderMap;
  range?: number;
  sheetName?: string;
  shouldExcludeUnmappedHeaders?: boolean;
  specificCellValues?: string[];
}

export interface UpdateOptions {
  sheetName: string;
  startRow?: number;
  endColumn?: string;
  persistantFormulaCells?: number[];
}

export interface UpdateFixedCell {
  address: string;
  value: string;
}

interface ColumnWidth {
  wch: number;
}

export interface GenerateOptions {
  fileName: string;
  sheetName: string;
  header: HeaderMap;
  columnWidth: ColumnWidth[];
  range?: number;
}

export interface ParsedExcelRow {
  [key: string]: unknown;
}

export interface RequestedCellValue {
  [key: string]: string | number;
}

const SUPPORTED_MIME_TYPES = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  'application/vnd.oasis.opendocument.spreadsheet'
];

const SUPPORTED_EXTENSION = ['xlsx', 'xls', 'xlsm', 'xlsb', 'odf'];

const getHeaders = (sheet: WorkSheet, options: ParserOptions): undefined | string[] => {
  const { headerMap, range, shouldExcludeUnmappedHeaders } = options;

  if (!headerMap) {
    return;
  }

  const { e: end } = utils.decode_range(sheet['!ref']);
  const { c: lastHeaderColumn } = end;

  const placeholderArray = Array.from(Array(lastHeaderColumn + 1));
  const headers = placeholderArray.map((_: undefined, c: number) => {
    const cellPosition = { c, r: range || 0 };
    const cellAddress = utils.encode_cell(cellPosition);
    const cell = sheet[cellAddress];
    const header = utils.format_cell(cell);

    let mappedHeader;

    for (const [key, value] of Object.entries(headerMap)) {
      if (key.toLowerCase() === header.toLowerCase()) {
        mappedHeader = value;
        break;
      }
    }

    if (shouldExcludeUnmappedHeaders) {
      return mappedHeader;
    }

    return mappedHeader || header;
  });

  return headers;
};

@Injectable()
export class ExcelService {
  private defaultParserOptions = {
    blankrows: false,
    defval: ''
  };

  parse<T = ParsedExcelRow>(file: File, options: ParserOptions = {}): Observable<[T[], RequestedCellValue]> {
    const parsedExcelSubject = new Subject<[ParsedExcelRow[], RequestedCellValue]>();
    const reader = new FileReader();

    if (!this.isSupportedType(file)) {
      return throwError('Unsupported file type');
    }

    reader.onload = event => this.readerOnLoad(event, options, parsedExcelSubject);
    reader.readAsBinaryString(file);

    return parsedExcelSubject.asObservable().pipe(take(1)) as Observable<[T[], RequestedCellValue]>;
  }

  generate<T>(body: T[], { sheetName, header, columnWidth, range }: GenerateOptions): ArrayBuffer {
    const workBook = utils.book_new();
    const sheet = utils.json_to_sheet([]);

    utils.sheet_add_json(sheet, [header, ...body], { skipHeader: true, origin: range });
    sheet['!cols'] = columnWidth;
    utils.book_append_sheet(workBook, sheet, sheetName);

    return write(workBook, { type: 'array' });
  }

  update(file: File, value: [][], fixedValues: UpdateFixedCell[] = [], options: UpdateOptions): void {
    const { sheetName, startRow, endColumn, persistantFormulaCells = [] } = options;

    XlsxPopulate.fromDataAsync(file).then((workbook: any) => {
      const sheet = workbook.sheet(sheetName).active(true);
      const startCell = `A${startRow}`;
      const endCell = `${endColumn}100`;
      const sourceRangeFormulas = [];

      sheet.range(startCell, endColumn + startRow).forEach(cell => {
        const formula = cell.formula();

        sourceRangeFormulas.push(formula);
      });

      sheet.range(startCell, endCell).clear().style({
        border: false,
        fill: undefined
      });

      value.forEach((valueRow, valueRowIndex) => {
        const rowNumber = startRow + valueRowIndex;
        const endColumnNumber = valueRow.length + 1;
        const range = sheet.range(rowNumber, 1, rowNumber, endColumnNumber);

        range.forEach((cell, _, columnIndex) => {
          const formula = sourceRangeFormulas[columnIndex];
          const cellValue = valueRow[columnIndex];

          if (cellValue && !persistantFormulaCells.includes(columnIndex + 1)) {
            cell.value(cellValue);

            return;
          }

          cell.formula(formula);
        });
      });

      if (fixedValues.length > 0) {
        fixedValues.forEach(({ address, value: v }) => {
          sheet.cell(address).value(v);
        });
      }

      workbook.outputAsync().then((blob: Blob) => {
        const fileUrl = window.URL.createObjectURL(blob);

        window.open(fileUrl);
      });
    });
  }

  private readerOnLoad(
    { target }: ProgressEvent,
    options: ParserOptions,
    parsedExcelSubject: Subject<[ParsedExcelRow[], RequestedCellValue]>
  ): void {
    const { sheetName, specificCellValues = [] } = options;
    const { result } = target as FileReader;
    const workBook: WorkBook = read(result, { type: 'binary' });
    const [firstWorkSheetName] = workBook.SheetNames;
    const workSheet: WorkSheet = workBook.Sheets[sheetName || firstWorkSheetName];

    if (!workSheet) {
      return parsedExcelSubject.error('Could not find target sheet');
    }

    try {
      const header = getHeaders(workSheet, options);
      const requestedCellValues = specificCellValues.reduce(
        (cellValues, cellAddress) => ({
          ...cellValues,
          [cellAddress]: workSheet[cellAddress]?.v
        }),
        {}
      );

      const parsedSheet = utils.sheet_to_json<ParsedExcelRow>(workSheet, {
        ...this.defaultParserOptions,
        ...options,
        header
      });

      if (header) {
        const [, ...parsedSheetNoHeaderRow] = parsedSheet;

        return parsedExcelSubject.next([parsedSheetNoHeaderRow, requestedCellValues]);
      }

      return parsedExcelSubject.next([parsedSheet, requestedCellValues]);
    } catch (e) {
      return parsedExcelSubject.error('Something unexpected happened while parsing excel file');
    }
  }

  private isSupportedType(file: File): boolean {
    if (file.type === '') {
      return SUPPORTED_EXTENSION.some(extension => {
        return new RegExp('.+\\.' + extension + '$').test(file.name);
      });
    }

    return SUPPORTED_MIME_TYPES.some(mimeType => mimeType.toLowerCase() === file.type.toLowerCase());
  }
}
