import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Pipe,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { AsteriskAlign, LabelAlign } from '../../models';

type InputType = 'text' | 'search' | 'number';

const INPUT_GROUP_CLASS_NAME = 'vle-input-group';
const DEFAULT_ERROR_MESSAGE = 'Invalid value';
const DEFAULT_VALIDATOR_MESSAGES: { [key: string]: string } = {
  min: 'Value is too small',
  max: 'Value is too big',
  required: 'Value is required',
  email: 'Email is incorrect',
  minlength: 'Value is too short',
  maxlength: 'Value is too long',
  pattern: 'Value does not match provided pattern'
};

const mapErrorMessages = (errors: ValidationErrors): string[] => {
  return Object.keys(errors).map(key => {
    const { message } = errors[key];

    if (errors[key].message) {
      return message;
    }

    return DEFAULT_VALIDATOR_MESSAGES[key];
  });
};

const getErrorMessage = ({ errors }: FormControl): string => {
  if (!errors) {
    return DEFAULT_ERROR_MESSAGE;
  }

  const [message] = mapErrorMessages(errors);

  return message || DEFAULT_ERROR_MESSAGE;
};

export interface InputLikeConfig {
  valuePipe?: Pipe;
  valuePipeArguments?: unknown[];
}

@Component({
  selector: 'vle-input-group',
  templateUrl: './input-group.component.html',
  styleUrls: ['./input-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputGroupComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  destroy$ = new Subject<void>();
  AsteriskAlign = AsteriskAlign;
  LabelAlign = LabelAlign;

  @Input() inputControl: FormControl;
  @Input() isDisabled?: boolean;
  @Input() inputType: InputType = 'text';
  @Input() isHorizontal = false;
  @Input() isAutocompleteEnabled?: boolean;
  @Input() placeholder: string = '';
  @Input() isAutofocus: boolean;
  @Input() isValidationHidden?: boolean;
  @Input() inputLikeConfig?: InputLikeConfig;
  @Input() labelText?: string;
  @Input() asteriskAlign?: AsteriskAlign;
  @Input() isTextarea? = false;
  @Input() isTextareaFixed? = true;
  @Input() isTextRightAligned? = false;
  @Input() textAreaRowsCount? = 2;

  @ViewChild('input', { static: false }) private input: ElementRef;

  @Output() private inputBlur = new EventEmitter<string>();
  @Output() private inputClick = new EventEmitter<MouseEvent>();
  @Output() private inputKeyUp = new EventEmitter<string>();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { inputControl, isDisabled } = changes;

    if (
      inputControl?.currentValue !== inputControl?.previousValue ||
      isDisabled?.currentValue !== isDisabled?.previousValue
    ) {
      if (this.isDisabled) {
        this.inputControl?.disable({ emitEvent: false });
      } else {
        this.inputControl?.enable({ emitEvent: false });
      }
    }

    this.inputControl.registerOnChange(() => this.cdr.markForCheck());
    this.inputControl.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.cdr.markForCheck());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    if (this.isAutofocus) {
      this.input.nativeElement.focus();
    }
  }

  onInputBlur(): void {
    this.inputBlur.emit(this.inputControl.value);
  }

  onInputClick(e: MouseEvent): void {
    this.inputClick.emit(e);
  }

  onKeyUp(): void {
    this.inputKeyUp.emit(this.inputControl.value);
  }

  stopPropagationIfDisabled(event: MouseEvent): void {
    if (this.inputControl.disabled) {
      event.stopPropagation();
    }
  }

  get inputGroupClassMap(): { [key: string]: boolean } {
    return {
      [`${INPUT_GROUP_CLASS_NAME}--horizontal`]: this.isHorizontal,
      [`${INPUT_GROUP_CLASS_NAME}--novalidate`]: this.isValidationHidden,
      [`${INPUT_GROUP_CLASS_NAME}--invalid`]: this.isInputInvalid,
      [`${INPUT_GROUP_CLASS_NAME}--readonly`]: this.isInputLike,
      [`${INPUT_GROUP_CLASS_NAME}--disabled`]: this.inputControl.disabled
    };
  }

  get isAutoCompleteValue(): string {
    return !!this.isAutocompleteEnabled ? 'on' : 'chrome-off';
  }

  get isInputInvalid(): boolean {
    const { touched, invalid } = this.inputControl;

    return touched && invalid;
  }

  get isInputLike(): boolean {
    return this.inputLikeConfig !== undefined;
  }

  get errorMessage(): string {
    return getErrorMessage(this.inputControl);
  }
}
