import { Directive } from '@angular/core';
import { ConverterService } from '../../services/converter.service';
import { FileDownloadService } from '../../services/file-download.service';

@Directive({
  selector: '[vleExport]',
  exportAs: 'vleExport'
})
export class ExportDirective {
  constructor(private converterService: ConverterService, private fileDownloadService: FileDownloadService) {}

  toCSV(fileName: string, data: string[][]): void {
    this.fileDownloadService.downloadFile(`${fileName}.csv`, this.converterService.prepareCSV(data));
  }
}
