export enum RowType {
  PRODUCT = 'Product',
  COMMENT = 'Comment',
  SUBTOTAL = 'Subtotal',
  EMPTY = 'Empty'
}

export enum ProductAttribute {
  EXCLUDED = 'EXCLUDED',
  HIDE_PRICE = 'HIDE_PRICE',
  PRINT_LINE = 'PRINT_LINE'
}

export interface BaseRow {
  id?: string;
  isSelected?: boolean;
  rowType: RowType;
}

export interface ProductRow extends BaseRow {
  [key: string]: any;
  attribute?: string;
}

export interface CommentRow extends BaseRow {
  text: string;
}

export type TableRow = ProductRow | CommentRow;

export enum EditorType {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  TYPEAHEAD = 'typeahead',
  DATEPICKER = 'datepicker',
  DROPDOWN = 'dropdown'
}

export interface MenuItem {
  id: string;
  value: string;
  rowType: RowType;
}

export enum MenuItemEventType {
  ADD = 'ADD',
  CLONE = 'CLONE',
  REMOVE = 'REMOVE',
  CUSTOM = 'CUSTOM'
}

export interface MenuItemEvent {
  type: MenuItemEventType;
  data?: any;
}

export interface CustomMenuItem {
  iconClass: string;
  tooltipText: string;
  eventType: any;
}

export interface RowMenuConfig {
  add: {
    show: boolean;
    tooltipText?: string;
    rowTypes: RowType[];
  };
  delete: {
    show: boolean;
    tooltipText?: string;
  };
  clone: {
    show: boolean;
    tooltipText?: string;
  };
  customItems?: CustomMenuItem[];
}

export interface DropdownActions {
  items: {
    label: string;
    action: string;
  }[];
  id: string;
  clickHandler: (value: { id: string; action: string }) => void;
  toggleElement: string;
}
