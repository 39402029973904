import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AsteriskAlign, LabelAlign } from '../../models';

const labelClassName = 'vle-label';

const labelClassMap = {
  [LabelAlign.Top]: `${labelClassName}--top`,
  [LabelAlign.Left]: `${labelClassName}--left`
};

@Component({
  selector: 'vle-label',
  templateUrl: 'label.component.html',
  styleUrls: ['label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelComponent {
  @Input() labelText?: string;
  @Input() labelAlign?: LabelAlign = LabelAlign.Top;
  @Input() asteriskAlign?: AsteriskAlign;
  @Input() isError? = false;

  AsteriskAlign = AsteriskAlign;

  get labelClassMap(): { [key: string]: boolean } {
    return {
      [labelClassMap[this.labelAlign]]: true,
      [`${labelClassName}--error`]: this.isError
    };
  }
}
