import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ButtonRole, DropdownItem, Filter, FilterableOp, FilterableType } from '../../../models';
import { DropdownDisplayValuePipe } from '../../../pipes';

@Component({
  selector: 'vle-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterComponent implements OnInit {
  @Input() filter: Filter;
  @Input() dateFormat? = 'MM/dd/yyyy';

  ButtonRole = ButtonRole;
  FilterableType = FilterableType;

  isFilterConditionOpen = false;
  isOperatorMenuOpen = false;
  operatorMenuItems: DropdownItem<number>[];
  form: FormGroup;
  filterLabel: string;
  datePipe = new DatePipe('en-US');
  DropdownDisplayValuePipe = DropdownDisplayValuePipe;

  private isClickedInside = false;

  private readonly operatorsByType: Record<FilterableType, FilterableOp[]> = {
    [FilterableType.LIST]: [FilterableOp.EQ, FilterableOp.NE],
    [FilterableType.TEXT]: [FilterableOp.LIKE, FilterableOp.EQ, FilterableOp.NE],
    [FilterableType.INPUT]: [FilterableOp.LIKE],
    [FilterableType.NUMBER]: [FilterableOp.EQ, FilterableOp.LT, FilterableOp.LE, FilterableOp.GT, FilterableOp.GE],
    [FilterableType.DATE]: [FilterableOp.SAME, FilterableOp.EA, FilterableOp.EB, FilterableOp.AF, FilterableOp.BF],
    [FilterableType.BOOLEAN]: [FilterableOp.EQ]
  };

  private readonly operatorsMap: Record<FilterableOp, string> = {
    [FilterableOp.LIKE]: 'contains',
    [FilterableOp.EQ]: 'equals',
    [FilterableOp.SAME]: 'is',
    [FilterableOp.NE]: 'is not',
    [FilterableOp.LT]: 'less',
    [FilterableOp.LE]: 'less or equal',
    [FilterableOp.GT]: 'greater',
    [FilterableOp.GE]: 'greater or equal',
    [FilterableOp.EA]: 'is same or after',
    [FilterableOp.EB]: 'is same or before',
    [FilterableOp.AF]: 'is after',
    [FilterableOp.BF]: 'is before'
  };

  @Output() private filterUpdated = new EventEmitter<Filter>();
  @Output() private filterRemoved = new EventEmitter<void>();

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    const operators: FilterableOp[] = this.operatorsByType[this.filter.filterable.type];

    this.operatorMenuItems = operators.map((operator, index) => ({
      value: index,
      displayValue: this.operatorsMap[operator]
    }));

    this.initForm();
    this.filterLabel = this.getFilterLabel();
  }

  get datepickerId(): string {
    return this.getId() + '__datepicker';
  }

  get dropdownId(): string {
    return this.getId() + '__dropdown';
  }

  @HostListener('click') onClickInside(): void {
    this.isClickedInside = true;
  }

  @HostListener('document:click') onClickOutside(): void {
    if (!this.isClickedInside) {
      if (this.isFilterConditionOpen && !this.isOperatorMenuOpen) {
        this.toggleFilterConditionState();
      }
    }

    this.isClickedInside = false;
  }

  onChangeOperator({ value }: DropdownItem): void {
    this.form.controls.operator.setValue(this.operatorMenuItems[Number(value)].displayValue);
  }

  onFilterRemove(): void {
    this.filterRemoved.emit();
  }

  onConditionUpdate(formValue: any): void {
    const operator = Object.keys(this.operatorsMap).find((key: FilterableOp) => {
      return this.operatorsMap[key] === formValue.operator;
    });

    const menuItem = this.filter.filterable.menuItems?.find(item => item.value === formValue.value) || {};

    this.filter = {
      ...this.filter,
      condition: {
        key: this.filter.filterable.name,
        value: formValue.value,
        operator: FilterableOp[operator as keyof typeof FilterableOp],
        ...menuItem
      }
    };

    this.filterUpdated.emit(this.filter);

    this.filterLabel = this.getFilterLabel();

    this.toggleFilterConditionState();
  }

  toggleFilterConditionState(): void {
    this.isFilterConditionOpen = !this.isFilterConditionOpen;

    if (this.isFilterConditionOpen) {
      this.initForm();
    } else {
      this.form.reset();
    }
  }

  onDropdownValueChanged(item: DropdownItem): void {
    this.form.get('value').setValue(item.value);
  }

  private initForm(): void {
    const { condition, filterable } = this.filter;
    const value =
      (condition && condition.value) ||
      (filterable.type === FilterableType.LIST ? filterable.menuItems[0]?.value : '');

    const operator = (condition && this.operatorsMap[condition.operator]) || this.operatorMenuItems[0].displayValue;

    this.form = this.formBuilder.group({
      value: [value, [Validators.required]],
      operator: [operator]
    });
  }

  private getFilterLabel(): string {
    let { label } = this.filter.filterable;
    const { hideConditionValue } = this.filter.filterable;

    if (this.filter.condition && !hideConditionValue) {
      let valueLabel = this.filter.condition.displayValue ?? this.filter.condition.value;

      if (this.filter.filterable.type === FilterableType.DATE) {
        valueLabel = this.datePipe.transform(valueLabel, this.dateFormat);
      }

      label += ` ${this.operatorsMap[this.filter.condition.operator]} ${valueLabel}`;
    }

    return label;
  }

  private getId(): string {
    return this.filter.filterable.label.replace(' ', '_');
  }
}
