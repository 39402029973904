import { Pipe } from '@angular/core';

export interface DropdownItem<T = string> {
  value: T;
  displayValue: string;
  iconName?: string;
  className?: string;
  isUnavailable?: boolean;
}

export interface ItemLikeConfig {
  valuePipe: Pipe;
  valuePipeArguments?: unknown;
}

export enum Trigger {
  CLICK = 'click',
  HOVER = 'hover'
}
