import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ButtonRole, DocumentTemplateSettingType, DropdownItem, Language, Theme } from '../../../models';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'vle-template-property-renderer',
  styleUrls: ['template-property-renderer.component.scss'],
  templateUrl: 'template-property-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplatePropertyRendererComponent implements OnChanges {
  @Input() controlType: DocumentTemplateSettingType;
  @Input() controlLabel: string;
  @Input() controlError?: string;
  @Input() controlValue?: string;
  @Input() controlLov?: string[];
  @Input() control?: FormControl;
  @Input() isPropertyFullScreen?: boolean;
  @Input() isDownloadButtonVisible?: boolean;
  @Input() isValidationHidden?: boolean = true;

  DocumentTemplateSettingType = DocumentTemplateSettingType;
  ButtonRole = ButtonRole;
  Language = Language;
  Theme = Theme;

  @Output() private fileSelect = new EventEmitter<File>();
  @Output() private togglePropertyExpand = new EventEmitter<void>();
  @Output() private downloadTemplate = new EventEmitter<void>();
  isControlInvalid$: Observable<boolean>;

  ngOnChanges(changes: SimpleChanges) {
    if (this.control && !this.isControlInvalid$) {
      this.isControlInvalid$ = this.control.statusChanges.pipe(map(() => this.control.touched && this.control.invalid));
    }
  }

  onDropdownItemSelect(itemValue: string): void {
    this.controlValueChange(itemValue);
  }

  onCheckboxChange(target: HTMLInputElement): void {
    this.controlValueChange(`${target.checked}`);
  }

  onRadioValueChange(value: string): void {
    this.controlValueChange(value);
  }

  onFileSelected(file: File): void {
    this.fileSelect.emit(file);
  }

  onTogglePropertyExpand(): void {
    this.togglePropertyExpand.emit();
  }

  onDownloadTemplate(): void {
    this.downloadTemplate.emit();
  }

  trackByValue = (_: number, value: string): string => value;

  private controlValueChange(newValue: string | boolean): void {
    this.control.setValue(newValue);
  }

  get dropdownMenuItems(): DropdownItem<string>[] {
    const mappedItems = this.controlLov?.map(value => ({ value, displayValue: value }));

    return mappedItems || [];
  }

  get controlClass(): { [key: string]: boolean } {
    return {
      'vle-template-property-renderer__control': !this.isDownloadButtonVisible,
      'vle-template-property-renderer__download-control': this.isDownloadButtonVisible
    };
  }

  get errorMessage(): string {
    return Object.values(this.control.errors)
      .map(({ message }) => message)
      .join(',');
  }
}
