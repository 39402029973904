import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MultiselectItem } from '../../models';

@Component({
  selector: 'vle-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiselectComponent implements OnChanges {
  arrowRightOpacity: number = 0.2;
  arrowLeftOpacity: number = 1;

  @Input() items: MultiselectItem[] = [];
  @Input() isSingle: boolean = true;
  @Input() firstColumnLabel: string = '';
  @Input() secondColumnLabel: string = '';
  @Input() isDisabled: boolean = false;
  @Output() handleItemMark = new EventEmitter<string>();
  @Output() handleSelecting = new EventEmitter<boolean>();

  ngOnChanges(): void {
    if (!this.isSingle) {
      this.items.filter(i => !i.isSelected).length === 0
        ? (this.arrowRightOpacity = 0.2)
        : (this.arrowRightOpacity = 1);
      this.items.filter(i => i.isSelected).length === 0 ? (this.arrowLeftOpacity = 0.2) : (this.arrowLeftOpacity = 1);
    }
  }

  markItem(item: MultiselectItem): void {
    // if 'isSingle === true' set 'isSelected' property together and the same as 'isMarked'
    // else don't touch 'isSelected' property, set only 'isMarked'
    this.handleItemMark.emit(item.id);
  }

  selectItems(selecting: boolean): void {
    // when 'selecting' is 'true', change 'isSelected' property of only those marked objects
    // that have 'isSelected: false'
    // when 'selecting' is 'false', change 'isSelected' property of only those marked objects
    // that is currently 'isSelected: true'
    // after that set 'isMarked' property of all objects to false
    this.handleSelecting.emit(selecting);
  }
}
