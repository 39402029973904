export const ModelLang = {
  keywords: [
    'boolean',
    'int',
    'double',
    'decimal',
    'string',
    'date',
    'true',
    'false',
    'attribute',
    'port',
    'constraint',
    'sequential',
    'serialized',
    'message',
    'preference',
    'require',
    'sum',
    'max',
    'min',
    'this',
    'sin',
    'cos',
    'tan',
    'cot',
    'sqrt',
    'abs',
    'ceil',
    'floor',
    'round',
    'year',
    'month',
    'dayofyear',
    'dayofmonth',
    'dayofweek',
    'workdays',
    'substr',
    'strlen',
    'today',
    'extern',
    'type',
    'property',
    'define',
    'import',
    '=',
    '>',
    '<',
    '!',
    '?',
    ':',
    '==',
    '<=',
    '>=',
    '!=',
    '&&',
    '||',
    '+',
    '-',
    '*',
    '/',
    '&',
    '|',
    '^',
    '%'
  ],

  symbols: /[=><!~?:&|+\-*\/\^%]+/,
  symbols0: /[\$%&\*\+@!\/\\\^~=\.:\-\?]/,
  idchars: /(?:\w|\-[a-zA-Z])*/,
  escapes: /\\(?:[abfnrtv\\"']|x[0-9A-Fa-f]{1,4}|u[0-9A-Fa-f]{4}|U[0-9A-Fa-f]{8})/,

  tokenizer: {
    root: [
      // identifiers and operators
      [
        /[a-z]@idchars/,
        {
          cases: {
            '@keywords': {
              cases: {
                alias: { token: 'keyword', next: '@alias_type' },
                struct: { token: 'keyword', next: '@struct_type' },
                'type|cotype|rectype': { token: 'keyword', next: '@type' },
                'module|as|import': { token: 'keyword', next: '@module' },
                '@default': 'keyword'
              }
            },
            '@default': 'identifier'
          }
        }
      ],

      [
        /([A-Z]@idchars)(\.?)/,
        { cases: { $2: ['identifier.namespace', 'keyword.dot'], '@default': 'identifier.constructor' } }
      ],

      [/_@idchars/, 'identifier.wildcard'],

      // whitespace
      { include: '@whitespace' },

      [/[{}()\[\]]/, '@brackets'],
      [/[;,`]/, 'delimiter'],

      // do not scan these as operators
      [/[<>](?![<>|]*@symbols0)/, '@brackets'],
      [/::?(?!@symbols0)/, 'type.operator', '@type'],
      [/::?(?=\?)/, 'type.operator', '@type'],

      // literal string
      [/@"/, { token: 'string.quote', bracket: '@open', next: '@litstring' }],

      // operators
      [/@symbols/, { cases: { '\\-': 'operator.minus', '@keywords': 'keyword.operator', '@default': 'operator' } }],

      // numbers
      [/[0-9]+\.[0-9]+([eE][\-+]?[0-9]+)?/, 'number.float'],
      [/0[xX][0-9a-fA-F]+/, 'number.hex'],
      [/[0-9]+/, 'number'],

      // strings
      [/"([^"\\]|\\.)*$/, 'string.invalid'],
      [/"/, { token: 'string.quote', bracket: '@open', next: '@string' }],

      // characters
      [/'[^\\']'/, 'string'],
      [/(')(@escapes)(')/, ['string', 'string.escape', 'string']],
      [/'/, 'string.invalid'],

      // meta
      [/^[ ]*#.*/, 'namespace']
    ],

    whitespace: [
      [/[ \r\n]+/, 'white'],
      ['/\\*', 'comment', '@comment'],
      ['//$', 'comment'],
      ['//', 'comment', '@line_comment']
    ],

    comment: [
      [/^\s*["|]\s*$/, 'comment', '@comment_docblock'],
      [/[^\/*"|]+/, 'comment'],
      ['/\\*', 'comment', '@push'],
      ['\\*/', 'comment', '@pop'],
      [/(")((?:[^"]|"")*)(")/, ['comment', 'comment.doc', 'comment']],
      [/(\|)((?:[^|]|\|\|)*)(\|)/, ['comment', 'comment.doc', 'comment']],
      [/[\/*"|]/, 'comment']
    ],

    comment_docblock: [
      [/\*\/|\/\*/, '@rematch', '@pop'],
      [/^\s*"\s*$/, 'comment', '@pop'],
      [/^\s*\|\s*$/, 'comment', '@pop'],
      [/[^*\/]+/, 'comment.doc'],
      [/./, 'comment.doc']
    ],

    line_comment: [
      [/[^"|]*$/, 'comment', '@pop'],
      [/[^"|]+/, 'comment'],
      [
        /(")((?:[^"]|"")*)(")/,
        ['comment', 'comment.doc', { cases: { '@eos': { token: 'comment', next: '@pop' }, '@default': 'comment' } }]
      ],
      [
        /(\|)((?:[^|]|\|\|)*)(\|)/,
        ['comment', 'comment.doc', { cases: { '@eos': { token: 'comment', next: '@pop' }, '@default': 'comment' } }]
      ],
      [/.*$/, 'comment', '@pop']
    ],

    string: [
      [/[^\\"]+/, 'string'],
      [/@escapes/, 'string.escape'],
      [/\\./, 'string.escape.invalid'],
      [/"/, { token: 'string.quote', bracket: '@close', next: '@pop' }]
    ],

    litstring: [
      [/[^"]+/, 'string'],
      [/""/, 'string.escape'],
      [/"/, { token: 'string.quote', bracket: '@close', next: '@pop' }]
    ],

    module: [
      { include: '@whitespace' },
      [
        /[a-z]@idchars/,
        {
          cases: {
            '@keywords': { token: '@rematch', next: '@pop' },
            '@default': 'identifier.namespace'
          }
        }
      ],
      [/[A-Z]@idchars/, 'identifier.namespace'],
      [/\.(?!@symbols)/, 'keyword.operator.dot'],
      ['', '', '@pop']
    ],

    alias_type: [
      ['=', 'keyword.operator'],
      { include: '@type' }
    ],

    struct_type: [
      [/\((?!,*\))/, '@brackets', '@pop'],
      { include: '@type' }
    ],

    type: [[/[(\[<]/, { token: '@brackets.type' }, '@type_nested'], { include: '@type_content' }],

    type_nested: [
      [/[)\]>]/, { token: '@brackets.type' }, '@pop'],
      [/[(\[<]/, { token: '@brackets.type' }, '@push'],
      [/,/, 'delimiter.type'],
      [/([a-z]@idchars)(\s*)(:)(?!:)/, ['type.identifier.typeparam', 'white', 'type.operator']],
      { include: '@type_content' }
    ],

    type_content: [
      { include: '@whitespace' },

      [/[*!](?!@symbols)/, 'type.kind.identifier'],
      [/[a-z][0-9]*(?![a-zA-Z_\-])/, 'type.identifier.typevar'],
      [/_@idchars/, 'type.identifier.typevar'],
      [
        /[a-z]@idchars/,
        {
          cases: {
            '@keywords': { token: '@rematch', next: '@pop' },
            '@default': 'type.identifier'
          }
        }
      ],
      [/[A-Z]@idchars(\.?)/, { cases: { $2: ['identifier.namespace', 'keyword.dot'], '@default': 'type.identifier' } }],

      ['', '', '@pop']
    ]
  }
};
