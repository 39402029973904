import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { AsyncValidatorFn, FormControl, ValidationErrors } from '@angular/forms';
import { catchError, map } from 'rxjs/operators';
import { ErrorLevel } from '../../validators/validators.utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class TemplateAdminValidationService {
  private serviceUrl = `${window['VELO_API']}/services/proxy`;

  constructor(private http: HttpClient) {}

  private getHeaders(): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Encoding': 'gzip' });
    let token = window['VELO_KEY'];

    if (token) {
      headers = headers.append('Authorization', token);
    }

    return headers;
  }

  public objectNameExists(): AsyncValidatorFn {
    return ({ value }: FormControl): Observable<ValidationErrors | null> => {
      if (!value) {
        return of(null);
      }
      return this.http
        .request<any[]>('post', `${this.serviceUrl}/query/EntityDefinition`, {
          body: {
            fields: ['QualifiedApiName'],
            skip: 0,
            count: 1,
            rawCondition: `QualifiedApiName = '${value}'`
          },
          headers: this.getHeaders()
        })
        .pipe(
          map(result => result?.length > 0),
          map(isExist => (!isExist ? { [ErrorLevel.ERROR]: { message: `${value} does not exist.` } } : null)),
          catchError(() => of({ [ErrorLevel.ERROR]: { message: `${value} does not exist.` } }))
        );
    };
  }

  public objectFieldsExists(): AsyncValidatorFn {
    return ({ value, parent }: FormControl): Observable<ValidationErrors | null> => {
      if (!value || (Array.isArray(value) && !value.length)) {
        return of(null);
      }
      const objectName = parent?.value.objectName;
      if (!objectName) {
        return of({ [ErrorLevel.ERROR]: { message: `Object name cannot be empty.` } });
      }
      const fieldNames = Array.isArray(value)
        ? value.map(v => v.trim())
        : value
            .split(',')
            .filter(v => !!v)
            .map(v => v.trim());
      return forkJoin(
        fieldNames.map(fieldName =>
          this.http
            .request<any>('get', `${this.serviceUrl}/describe/${objectName}/fields/${fieldName}`, {
              headers: this.getHeaders()
            })
            .pipe(catchError(() => of({ isError: true, fieldName })))
        )
      ).pipe(
        map((fields: any[]) => fields.filter(field => !!field?.isError).map(field => field.fieldName)),
        map((fieldNames: string[]) =>
          fieldNames.length > 0
            ? {
                [ErrorLevel.ERROR]: {
                  message: `${fieldNames.join(',')} do${fieldNames.length === 1 ? 'es' : ''} not exist`
                }
              }
            : null
        )
      );
    };
  }
}
