import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonRole } from '../../../../models';

@Component({
  selector: 'vle-group-renderer',
  templateUrl: 'group-renderer.component.html',
  styleUrls: ['group-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupRendererComponent implements ICellRendererAngularComp {
  cellParams: ICellRendererParams;
  hasChild: boolean;
  get isExpand(): boolean {
    const { context, node } = this.cellParams;
    return context.componentParent.openGroupsIds.includes(node.data.id);
  }

  buttonRole = ButtonRole.Plain;

  agInit(params: ICellRendererParams): void {
    this.cellParams = params;
    this.hasChild = !!params.data.children?.length;

    if (params.data.isExpanded) {
      this.onExpandButtonClick(true);
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return true;
  }

  onExpandButtonClick(shouldPreventEmit?: boolean): void {
    const { context, node } = this.cellParams;

    if (this.isExpand) {
      context.componentParent.removeChildRow(node);
    } else {
      context.componentParent.addChildRow(node);
    }

    if (!shouldPreventEmit) {
      context.componentParent.onRowExpandToggle({
        isExpanded: this.isExpand,
        data: node.data
      });
    }
  }
}
