export enum AsteriskAlign {
  Left = 'Left',
  Right = 'Right'
}

export enum LabelAlign {
  Top = 'Top',
  Left = 'Left'
}
export interface Foo {
  id?: string;
  data?: any;
}
